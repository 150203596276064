.implementation-animation {
  width: 343.3px;
  height: 258.24px;
  position: relative;

  .setting1 {
    position: absolute;
    left: 35.07px;
    top: 126.53px;
  }
  .setting2 {
    position: absolute;
    left: 174.8px;
    top: 111.48px;
  }
  .setting3 {
    position: absolute;
    left: 115.75px;
    top: 1.48px;
  }
  .setting4 {
    position: absolute;
    left: 2px;
    top: 166.26px;
  }
  .setting5 {
    position: absolute;
    left: 259.85px;
    top: 72.76px;
  }
  .setting6 {
    position: absolute;
    left: 52.16px;
    top: 66.12px;
  }
  .setting7 {
    position: absolute;
    left: 156.04px;
    // top: ;
  }
  .setting8 {
    position: absolute;
    left: 111.86px;
    top: 90.32px;
  }

  .settings {
    svg {
      animation: settingRotate 4s linear infinite;
    }

    @keyframes settingRotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
