.staff-hero-section {
  .main-section {
    // padding: 90px 0 149px;
    padding: 90px 0 120px;
  }
}

.digital-team-bg {
  background: #091a2a;
  overflow: hidden;
  position: relative;
  padding: 120px 0 140px;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  .subdata-card-box {
    border-color: #aeaeef;
    h4 {
      color: #aeaeef;
    }
    span {
      color: #b5b5c5;
    }
  }
}
.spancolor {
  span {
    color: #44444d;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
  }
}
.bg-remove {
  .draktalktous-section {
    background-color: transparent;
    .talktous-text {
      span {
        color: #44444d;
      }
    }
  }
}

.bg-add {
  overflow: hidden;
  position: relative;
  .enterprisegains-section {
    background: #091a2a;
    .accordian-section {
      .accordian-item {
        .accordian-head {
          .svg-icon {
            background-color: #aeaeef;
            svg {
              path {
                stroke: #000;
              }
            }
          }
          h3 {
            color: #aeaeef;
          }
        }
        .accordian-body {
          &::before {
            background-color: #aeaeef;
          }
          .accordian-body-data {
            padding-bottom: 0;
          }
          span {
            color: #b5b5c5;
            ul {
              li {
                color: #b5b5c5;
              }
            }
          }
          a {
            display: none;
          }
        }
      }
      .openitem {
        .accordian-head {
          .svg-icon {
            box-shadow: 0 0 5px 3px #926ca5;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .staff-hero-section {
    .main-section {
      // padding: 68px 0 142px;
      padding: 68px 0 122px;
    }
  }
  .digital-team-bg {
    padding: 60px 0 160px;
    margin: 0px !important;
    .subdata-card-box {
      border-color: #aeaeef;
      h4 {
        color: #aeaeef;
      }
      span {
        color: #b5b5c5;
      }
    }
  }
}
