.importance-section {
  padding-top: 80px;
  padding-bottom: 112px;
  overflow: hidden;
  position: relative;
  &::before {
    content: ' ';
    border-radius: 216px;
    background: #9437ff;
    width: 216px;
    height: 216px;
    // right: 12px;
    left: -76px;
    top: 24px;
    position: absolute;
    filter: blur(250px);
  }
  .container {
    position: relative;
    h1 {
      color: #4e4fdc;
      font-family: 'Epilogue', sans-serif !important;
      font-size: 52px;
      font-weight: 800;
      line-height: 66px;
      margin-bottom: 57px;
    }
    .span-text {
      max-width: 602px;
      span {
        color: #44444d;
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
      }
    }
    .griddot {
      position: absolute;
      right: 0px;
      bottom: -112px;
    }
  }
  // .griddot{
  //     position: absolute;
  //     right: 228px;
  //     bottom: 10px;
  // }
}

@media (max-width: 991.5px) {
  .importance-section {
    .container {
      .griddot {
        //right: 0px;
        //bottom: -291px;
        // right: 50px;
        display: none;
      }
    }
  }
}

@media (max-width: 768px) {
  .importance-section {
    padding-top: 70px;
    padding-bottom: 160px;
    &::before {
      // right: -172px;
      // top: -66px;
      left: unset;
      right: 10px;
      top: 60px;
    }
    .container {
      h1 {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 50px;
      }
      .griddot {
        right: 10px;
        // bottom: -171px;
        bottom: -301px;
      }
    }
  }
}
