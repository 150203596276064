.staff-animation {
  width: 261px;
  height: 212px;
  /* background-color: #091a2a; */
  position: relative;
  /* top: 559px;
    left: 212px; */
}

.staff-animation .row-1 {
  top: 17.5px;
  left: -180.49px;
  position: absolute;
  animation: translateRight 8s infinite forwards;
}

.staff-animation .row-2 {
  top: 80.03px;
  left: 260.49px;
  position: absolute;
  animation: translateLeft 8s infinite forwards;
}

.staff-animation .stars-animation {
  top: 157.73px;
  left: 39.21px;
  position: absolute;
}

.stars-animation path {
  animation: glowStarAnimation 8s infinite forwards;
}

.staff-animation .star-container {
  top: 145.59px;
  left: 16.5px;
  position: absolute;
  animation: showStarBox 8s infinite forwards;
}

@keyframes translateRight {
  0% {
    opacity: 0;
  }

  10%,
  100% {
    left: 40.49px;
    /* transform: translateX(40px); */
    opacity: 1;
  }
}

@keyframes translateLeft {
  0% {
    opacity: 0;
  }

  10%,
  100% {
    left: 16.49px;
    /* transform: translateX(40px); */
    opacity: 1;
  }
}

@keyframes showStarBox {
  0% {
    opacity: 0;
  }

  60%,
  100% {
    opacity: 1;
  }
}

@keyframes glowStarAnimation {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
    stroke: #8889e8;
  }

  51% {
    stroke: #ffa900;
  }

  100% {
    stroke: #ffa900;
  }
}
