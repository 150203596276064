.solutions-desktop .solutions-hero-2 {
  height: 277px;
  position: relative;
  width: 334px;
}

.solutions-mobile .solutions-hero {
  height: 277px;
  position: relative;
  width: 334px;
}

.solutions-hero path:nth-child(3),
.solutions-hero-2 path:nth-child(3) {
  animation: glowAnimation 7s infinite;
}
.solutions-hero path:nth-child(4),
.solutions-hero-2 path:nth-child(4) {
  animation: glowAnimation2 7s infinite;
}

.solutions-hero path:nth-child(5),
.solutions-hero-2 path:nth-child(5) {
  animation: glowAnimation 7s forwards infinite;
  animation-delay: 2s;
}
.solutions-hero path:nth-child(6),
.solutions-hero-2 path:nth-child(6) {
  animation: glowAnimation2 7s forwards infinite;
  animation-delay: 2s;
}

@keyframes glowAnimation {
  0% {
    stroke: #8889e8;
  }
  33% {
    stroke: #9654f4;
  }
  66% {
    stroke: #8889e8;
  }
}
@keyframes glowAnimation2 {
  0% {
    stroke: #8889e8;
  }
  33% {
    stroke: #9654f4;
    fill: #9654f4;
  }
  66% {
    stroke: #8889e8;
  }
}

.solutions-hero path:nth-child(7),
.solutions-hero-2 path:nth-child(7) {
  animation: movePath 7s infinite forwards;
  animation-delay: 5s;
}
.solutions-hero path:nth-child(8),
.solutions-hero-2 path:nth-child(8) {
  animation: moveCircle 7s infinite forwards;
  animation-delay: 5s;
}

@keyframes movePath {
  0% {
    transform: translate(0, 0);
    stroke: orange;
    /* fill: transparent; */
  }
  25% {
    stroke: orange;
    fill: transparent;
  }
  33% {
    transform: translate(-32%, -53%);
    stroke: orange;
    fill: orange;
  }
  40% {
    transform: translate(-32%, -53%);
    stroke: orange;
    fill: orange;
    transform: translate(0%, 0%);
    stroke: transparent;
    fill: transparent;
  }

  44% {
    transform: translate(0%, 0%);
    stroke: #8889e8;
  }

  98% {
    stroke: #8889e8;
    transform: translate(0%, 0%);
  }
  100% {
    stroke: #8889e8;
    transform: translate(0%, 0%);
  }
}

@keyframes moveCircle {
  0% {
    transform: translate(0, 0);
    stroke: orange;
    fill: orange;
  }
  25% {
    stroke: orange;
    fill: orange;
  }
  33% {
    transform: translate(-32%, -53%);
    stroke: orange;
    fill: orange;
  }

  40% {
    transform: translate(-32%, -53%);
    stroke: orange;
    fill: orange;
    transform: translate(0%, 0%);
    stroke: #8889e8;
    fill: #8889e8;
  }
  44% {
    transform: translate(0%, 0%);
    stroke: #8889e8;
    fill: #8889e8;
  }

  98% {
    stroke: #8889e8;
    fill: #8889e8;
    transform: translate(0%, 0%);
  }
  100% {
    stroke: #8889e8;
    fill: #8889e8;
    transform: translate(0%, 0%);
  }
}
