.pam-page {
  .importance-section {
    &::before {
      right: 41px;
      left: unset;
      top: -42px;
    }
  }
}

.pam-hero-section {
  .main-section {
    padding: 90px 0 120px;
  }
}

@media (max-width: 768px) {
  .pam-hero-section {
    .main-section {
      // padding: 68px 0 112px;
      padding: 68px 0 122px;
    }
  }

  .pam-page {
    .importance-section {
      &::before {
        right: -52px;
        left: unset;
        top: -66px;
      }
    }
  }
}
