.contact-hero-section {
  .main-section {
    // padding: 160px 0px 117px;
    padding: 90px 0px 120px;
  }
}

.complysimpli-section {
  margin-top: 143px;
  .complysimpli-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    align-items: start;
    // svg{
    //     padding-right: 50px;
    // }
    img {
      width: 100%;
      padding-right: 50px;
    }
    h1 {
      color: #4e4fdc;
      font-family: 'Epilogue', sans-serif !important;
      font-size: 32px;
      font-weight: 800;
      line-height: 40px;
      text-transform: capitalize;
      margin-bottom: 18px;
    }
    ul {
      list-style: disc;
      margin-left: 25px;
      li {
        color: #44444d;
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
        b {
          font-weight: 700;
        }
      }
    }
  }
}

.contact-section {
  padding: 80px 0 155px;
  margin-top: 175px;
  .contact-row {
    position: relative;
    display: grid;
    gap: 24px;
    grid-template-columns: 2fr 3fr;
    .contact-text {
      padding-right: 56px;
      h1 {
        color: #4e4fdc;
        font-family: 'Epilogue', sans-serif !important;
        font-size: 36px;
        font-weight: 800;
        line-height: 40px;
        text-transform: capitalize;
        margin-bottom: 18px;
      }
      span {
        color: #b5b5c5;
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
      }
      hr {
        border-color: #aeaeef;
        margin: 20px 0 53px;
      }
    }
    .form-box {
      padding-top: 68px;
      label {
        color: #8889e8;
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
      }
      form {
        margin-top: 32px;
        border-radius: 8px;
        background: #ededfc;
        padding: 59px 36px;
        .formcol2 {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 22px;
        }
        .input-group {
          margin-bottom: 30px;
          label {
            color: #4e4fdc;
            font-size: 16px;
            font-weight: 700;
            line-height: 16.364px;
          }
          input {
            margin-top: 10px;
            border-radius: 4px;
            padding: 22px;
            background: #fff;
            border: 0;
            outline: 0;
            width: 100%;
            &::placeholder {
              font-size: 14px;
              color: #adabc3;
              font-family: 'Poppins', sans-serif !important;
            }
          }
          textarea {
            margin-top: 10px;
            border-radius: 4px;
            padding: 22px;
            background: #fff;
            border: 0;
            outline: 0;
            width: 100%;
            font-family: 'Poppins', sans-serif !important;
          }
        }
        button {
          border-radius: 8px;
          background: #4e4fdc;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          outline: 0;
          border: 0;
          display: flex;
          padding: 24px 36px;
          align-items: flex-start;
          gap: 8px;
          color: #fff;
          text-align: center;
          font-family: 'Poppins', sans-serif !important;
          font-size: 18px;
          font-weight: 500;
          line-height: 18px;
          cursor: pointer;
          &:hover {
            background: #4748c8;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.call-section {
  padding: 70px 0 160px;
  position: relative;
  overflow: hidden;
  &::before {
    content: ' ';
    border-radius: 261px;
    background: #9437ff;
    width: 261px;
    height: 261px;
    right: -111px;
    top: -48px;
    position: absolute;
    filter: blur(250px);
  }
  .griddot {
    position: absolute;
    right: 231px;
    bottom: 20px;
    z-index: -1;
  }
  h1 {
    color: #4e4fdc;
    font-family: 'Epilogue', sans-serif !important;
    font-size: 32px;
    font-weight: 800;
    line-height: 40px;
    text-transform: capitalize;
    margin-bottom: 18px;
    position: relative;
  }
  span {
    color: #44444d;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    position: relative;
  }
  button {
    position: relative;
    border-radius: 8px;
    background: #4e4fdc;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    outline: 0;
    border: 0;
    display: flex;
    padding: 24px 36px;
    align-items: flex-start;
    gap: 8px;
    color: #fff;
    text-align: center;
    font-family: 'Poppins', sans-serif !important;
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    margin-top: 70px;
    cursor: pointer;
    &:hover {
      background: #4748c8;
      cursor: pointer;
    }
  }
}

.ready-mar0 {
  .readytouse-section {
    margin-top: 0;
  }
}

// responsive
@media (max-width: 991.5px) {
  .complysimpli-section {
    .complysimpli-row {
      gap: 20px;
      svg {
        padding-right: 0;
      }
    }
  }
  .contact-section {
    .contact-row {
      gap: 20px;
      .contact-text {
        padding-right: 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .contact-hero-section {
    .main-section {
      // padding: 68px 0px 109px;
      padding: 68px 0px 122px;
    }
  }

  .complysimpli-section {
    margin-top: 98px;
    .complysimpli-row {
      grid-template-columns: 1fr;
      gap: 70px;
      img {
        padding-right: 0px;
      }
      h1 {
        margin-bottom: 28px;
      }
    }
  }
  .contact-section {
    padding: 109px 0 164px;
    margin-top: 100px;
    .contact-row {
      gap: 77px;
      grid-template-columns: 1fr;
      .contact-text {
        padding-right: 0;
        h1 {
          font-size: 32px;
          margin-bottom: 28px;
        }
        hr {
          margin: 41px 0 10px;
        }
      }
      .form-box {
        padding-top: 0;
        form {
          margin-top: 28px;
          padding: 50px 23px;
          .formcol2 {
            grid-template-columns: 1fr;
            gap: 0;
          }
          .input-group {
            margin-bottom: 29px;
          }
        }
      }
    }
  }
  .call-section {
    padding: 80px 0 239px;
    &::before {
      right: -82px;
      top: -66px;
    }
    .griddot {
      right: 19px;
    }
    h1 {
      margin-bottom: 28px;
    }
    button {
      margin-top: 32px;
    }
  }
}

@media (max-width: 320px) {
  .complysimpli-section {
    .complysimpli-row {
      h1 {
        font-size: 28px;
      }
    }
  }
}
