.readytouse-section {
  background-image: url('../../../assets/images/2985c259794874ac81e67a9dbafc08a2.jpg');
  background-position: center center;
  background-size: cover;
  position: relative;
  margin-top: 120px;
  &::before {
    background-color: #4e4fdce5;
    position: absolute;
    height: 100%;
    width: 100%;
    content: ' ';
    opacity: 0.9;
  }
  .container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    position: relative;
    // padding: 100px 15px;
    padding: 132px 15px;
    gap: 100px;
    align-items: flex-start;
    h1 {
      color: #fff;
      font-family: 'Epilogue', sans-serif !important;
      font-size: 36px;
      font-weight: 800;
      line-height: 40px;
      margin: 0;
      margin-bottom: 24px;
    }
    span {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
    }
    button {
      border-radius: 8px;
      background: #fff;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      outline: 0;
      border: 2px solid #4e4fdc;
      display: flex;
      // padding:  24px 36px;
      padding: 24px 56px;
      align-items: center;
      gap: 8px;
      margin-top: 114px;
      // width: 100%;
      width: 307px;
      color: #4e4fdc;
      text-align: center;
      font-family: 'Poppins', sans-serif !important;
      font-size: 20px;
      font-weight: 500;
      line-height: 20px;
      cursor: pointer;
      justify-content: center;
    }
  }
}

@media (max-width: 991.5px) {
  .readytouse-section {
    .container {
      grid-template-columns: 1fr;
      gap: 30px;
      button {
        margin-top: 0;
        // width: fit-content;
        width: 307px;
      }
    }
  }
}

@media (max-width: 768px) {
  .readytouse-section {
    margin-top: 133px;
    .container {
      padding: 110px 15px 140px;
      gap: 40px;
      h1 {
        font-size: 32px;
      }
      button {
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 360px) {
  .readytouse-section {
    .container {
      grid-template-columns: 1fr;
      gap: 30px;
      button {
        margin-top: 0;
        // width: fit-content;
        width: 260px;
        padding: 24px;
      }
    }
  }
}
