.main-section {
  // padding: 100px 0px 120px;
  padding: 90px 0px 120px;
  .main-section-data {
    position: relative;
    //max-width: 780px;
    display: grid;
    grid-template-columns: 3fr 1fr;
    // justify-items: center;
    justify-content: space-between;
    gap: 40px;
    align-items: center;

    .main-section-content {
      height: 100%;

      max-width: 661px;
    }

    label {
      color: #9654f4;
      font-size: 18px;
      font-weight: 500;
      line-height: 30px;
    }
    h1 {
      color: #e2e2e9;
      font-family: 'Epilogue', sans-serif !important;
      font-size: 52px;
      font-weight: 800;
      line-height: 66px;
      margin: 20px 0;
    }
    span {
      color: #b5b5c5;
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
    }
    //.animationimg{
    //margin-top: 42px;
    //}
  }
}

// responsive
@media (max-width: 991.5px) {
  .main-section {
    padding: 100px 0px 53px;
    .main-section-data {
      grid-template-columns: 1fr;
      h1 {
        font-size: 36px;
        line-height: 40px;
      }
    }
  }
}

@media (max-width: 768px) {
  .main-section {
    padding: 68px 0px 122px;
    .main-section-data {
      label {
        font-size: 14px;
        line-height: 20px;
      }
      h1 {
        font-size: 36px;
        line-height: 40px;
        margin: 36px 0;
      }
      .animationimg {
        text-align: center;
        margin-top: 56px;
      }
    }
  }
}
