.solution-hero-section {
  .main-section {
    // padding: 90px 0 75px;
    padding: 90px 0 120px;
  }
}

.serandsol-section.removehead-margin {
  padding-bottom: 144px;
  .serandsol-data-box {
    margin-top: 30px;
    .serandsol-box {
      h4 {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .solution-hero-section {
    .main-section {
      // padding: 68px 0 81px;
      padding: 68px 0 122px;
    }
  }
}
