.opssupport-animation {
  width: 281.2px;
  height: 169.15px;
  /* background-color: #091a2a; */
  position: relative;
  /* top: 387px;
    left: 223px; */
}

.opssupport-animation .clock {
  position: absolute;
  top: 104.15px;
  left: 224px;
}

.opssupport-animation .sub-ops {
  position: absolute;
  top: 35.15px;
}

.opssupport-animation .gear-sub-1 {
  position: absolute;
  left: 188.97px;
  top: 15px;
  animation: rotate-gear 4s linear infinite;
}

.opssupport-animation .gear-sub-2 {
  position: absolute;
  left: 156.59px;
  top: 52.67px;
  animation: rotate-gear 4s linear infinite;
}

.opssupport-animation .cloud-img {
  position: absolute;
  top: 30px;
  left: 0px;
}

.opssupport-animation .cloud-line {
  position: absolute;
  top: 110px;
  left: 55px;
}

.sub-ops .opsupport-lock path {
  animation: colorLock 8s infinite forwards;
  animation-delay: 4s;
}

.opssupport-animation .todo-svg {
  position: absolute;
  top: 52px;
  left: 16px;
}

.todo-svg .todo-1 path {
  animation: loadFirst 8s infinite forwards;
}
.todo-svg .todo-2 path {
  animation: loadSecond 8s infinite forwards;
}
.todo-svg .todo-3 path {
  animation: loadThird 8s infinite forwards;
}

@keyframes loadFirst {
  0% {
    opacity: 0;
  }

  10%,
  100% {
    opacity: 1;
  }
}
@keyframes loadSecond {
  0%,
  15% {
    opacity: 0;
  }

  25%,
  100% {
    opacity: 1;
  }
}
@keyframes loadThird {
  0%,
  35% {
    opacity: 0;
  }

  45%,
  100% {
    opacity: 1;
  }
}

@keyframes colorLock {
  0% {
    stroke: #8889e8;
  }

  10%,
  45% {
    stroke: #ffa900;
  }

  50%,
  100% {
    stroke: #8889e8;
  }
}

.cloud-line {
  animation: translateCloud 8s infinite forwards;
  animation-delay: 4s;
  opacity: 0;
}

@keyframes translateCloud {
  0% {
    opacity: 0;
    /* transform: scale(0); */
  }

  22%,
  45% {
    /* transform: ; */
    transform: translate(-24px, 27px);
    /* top: 137px;
      left: 34px; */
    opacity: 1;
  }

  50%,
  100% {
    opacity: 0;
  }
}

.cloud-img .cloud2 {
  animation: cloudAnimation 8s infinite forwards;
  transform-origin: center;
  animation-delay: 4s;
}

@keyframes cloudAnimation {
  0% {
    stroke: #8889e8;
  }

  10% {
    transform: translate(-20px, 13px);
    stroke: transparent;
    opacity: 0;
    /* transform-origin: center right; */
  }

  11% {
    transform: translate(0, 0);
    opacity: 0;
    stroke: #8889e8;
  }

  100% {
    transform: translate(0, 0);
    opacity: 1;
    stroke: #8889e8;
    /* transform-origin: right center; */
  }
}

@keyframes rotate-gear {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
