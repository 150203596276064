.iga-page {
  .importance-section {
    &::before {
      left: 226px;
      // left: unset;
      top: -66px;
    }
  }
}

.iga-hero-section {
  .main-section {
    padding: 90px 0 120px;
  }
}

@media (max-width: 768px) {
  .iga-hero-section {
    .main-section {
      // padding: 68px 0 104px;
      padding: 68px 0 122px;
    }
  }

  .iga-page {
    .importance-section {
      &::before {
        left: -92px;
        // left: unset;
        top: 200px;
      }
    }
  }
}

@media (max-width: 600px) {
  .col2vendor {
    .vendor-img {
      img {
        height: 34px;
        object-fit: contain;
        width: 150px;
        text-align: center;
        &:nth-child(2) {
          order: 2;
        }
        &:nth-child(3) {
          order: 3;
          width: 225%;
        }
      }
    }
  }
}

@media (max-width: 350px) {
  .col2vendor .vendor-img img {
    width: 120px;
  }
}
