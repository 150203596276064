.strategy-hero-section {
  .main-section {
    padding: 90px 0 120px;
  }
}

.intro-section {
  padding: 130px 0 140px;
  position: relative;
  overflow: hidden;
  .intro-bg-mobile {
    display: none;
  }
  &::after {
    content: ' ';
    width: 216px;
    height: 216px;
    border-radius: 216px;
    background: #9437ff;
    filter: blur(250px);
    position: absolute;
    top: 495px;
    right: -43px;
  }
  .intro-bg {
    position: relative;
    max-width: 100%;
    margin: 0 auto;
    height: 100%;

    svg {
      position: absolute;
      left: 50%;
      bottom: -50%;
      transform: translateX(-50%) translateY(115%);
    }

    svg .line-animation {
      stroke-dasharray: 5400;
      stroke-dashoffset: 0;
      animation: lineThrough 7s ease-in-out reverse;
    }

    @keyframes lineThrough {
      to {
        stroke-dashoffset: 5400;
      }
    }
  }
  .intro-text {
    label {
      color: #737482;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
    }
    h1 {
      color: #4e4fdc;
      font-family: 'Epilogue', sans-serif !important;
      font-size: 52px;
      font-weight: 800;
      line-height: 66px;
      margin: 18px 0;
    }
    span {
      color: #44444d;
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
    }
  }
  .intro-content-row {
    position: relative;
    display: grid;
    grid-template-columns: 4fr 3fr;
    gap: 24px;
    .intro-content-box {
      border-radius: 4px;
      background: #ededfc;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
      padding: 49px 40px;
      display: flex;
      flex-direction: column;
      margin-top: 55px;
      margin-right: auto;
      width: fit-content;
      cursor: pointer;
      &:hover {
        background: #efe6fd;
      }
      img {
        margin: auto;
        margin-bottom: 24px;
        width: 202px;
      }
      label {
        color: #737482;
        font-family: 'Epilogue', sans-serif !important;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 1.7px;
      }
      h1 {
        color: #4e4fdc;
        font-family: 'Epilogue', sans-serif !important;
        font-size: 31px;
        font-weight: 800;
        line-height: 40px;
        margin: 18px 0;
      }
      span {
        color: #44444d;
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
      }
      ul {
        list-style: disc;
        margin-left: 20px;
        li {
          color: #44444d;
          font-size: 14px;
          font-weight: 400;
          line-height: 26px;
          b {
            font-weight: 700;
          }
        }
      }
    }
  }
  .reverse-col {
    grid-template-columns: 3fr 4fr;
  }
}

@media (max-width: 991.5px) {
  .intro-section {
    .intro-content-row {
      grid-template-columns: 4fr 1fr;
    }
    .reverse-col {
      grid-template-columns: 1fr 4fr;
    }
  }
}

@media (max-width: 768px) {
  .strategy-hero-section {
    .main-section {
      // padding: 68px 0 163px;
      padding: 68px 0 122px;
    }
  }

  .intro-section {
    padding: 70px 0 100px;
    .container {
      // &::before {
      //     content: " ";
      //     background-color: #9654F4;
      //     width: 3px;
      //     height: 67%;
      //     position: absolute;
      //     left: 50%;
      //     top: 470px;
      // }

      .intro-bg-mobile {
        position: relative;
        max-width: 100%;
        margin: 0 auto;
        height: 100%;
        z-index: -2;
        display: flex;
        justify-content: center;
        svg {
          position: absolute;
          top: -70px;
        }

        #strategy-mobile-draw-animation-1,
        #strategy-mobile-draw-animation-2 {
          stroke-dasharray: 250;
          stroke-dashoffset: 0;
          animation: home-mobile-draw 2s linear reverse;
        }

        @keyframes home-mobile-draw {
          to {
            stroke-dashoffset: 250;
          }
        }
      }
    }
    &::after {
      display: none;
    }
    .intro-bg {
      display: none;
    }
    .intro-text {
      h1 {
        font-size: 32px;
        line-height: 40px;
        margin: 28px 0;
      }
    }
    .intro-content-row {
      grid-template-columns: 1fr;
      gap: 0;
      margin-top: 74px;
      .intro-content-box {
        padding: 47px 16px 40px;
        margin-top: 0;
      }
      div {
        display: none;
      }
    }
    .reverse-col {
      grid-template-columns: 1fr;
    }
  }
}
