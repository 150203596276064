.secondmain-section {
  padding: 90px 0 120px;
  .secondmain-section-data {
    position: relative;
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 24px;
    label {
      color: #9654f4;
      font-size: 18px;
      font-weight: 500;
      line-height: 30px;
    }
    h1 {
      color: #e2e2e9;
      font-family: 'Epilogue', sans-serif !important;
      font-size: 56px;
      font-weight: 800;
      line-height: 66px;
      margin: 20px 0;
    }
    span {
      color: #b5b5c5;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
    }
    .animationimg {
      display: flex;
      justify-content: center;
      svg {
        width: 229px;
        height: auto;
      }
    }
  }
  .secondmain-box-data {
    margin-top: 92px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 58px;
    margin-bottom: 115px;
    .secondmain-box {
      border-radius: 12px;
      background: linear-gradient(114deg, #4748c8, #26157e);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      padding: 48px 16px 38px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      h3 {
        color: #fff;
        text-align: center;
        font-family: 'Epilogue', sans-serif !important;
        font-size: 16px;
        font-weight: 700;
        line-height: 24.5px;
        margin-bottom: 6px;
      }
      svg {
        margin-bottom: 18px;
        width: 100%;
        min-height: 18px;
      }
      span {
        color: #b5b5c5;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        min-height: 110px;
      }
      h4 {
        color: #fff;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        line-height: 26px;
        margin-top: 18px;
      }
      label {
        color: #b5b5c5;
        text-align: center;
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
        margin-top: 8px;
        height: 34px;
      }
    }
  }
}

.ourletsbegin-section {
  padding: 130px 0 153px;
  position: relative;
  overflow: hidden;
  &::after {
    content: ' ';
    border-radius: 261px;
    background: #9437ff;
    width: 261px;
    height: 261px;
    right: 0;
    bottom: -30px;
    position: absolute;
    filter: blur(250px);
  }
  .ourletsbegin-text {
    position: relative;
    label {
      color: #737482;
      font-family: 'Epilogue', sans-serif !important;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 1.7px;
    }
    h1 {
      color: #4e4fdc;
      font-family: 'Epilogue', sans-serif !important;
      font-size: 36px;
      font-weight: 800;
      line-height: 40px;
      text-transform: capitalize;
      margin: 18px 0;
    }
    span {
      color: #44444d;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
    }
    button {
      border-radius: 8px;
      background: #4e4fdc;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      outline: 0;
      border: 0;
      display: flex;
      padding: 24px 36px;
      align-items: flex-start;
      gap: 8px;
      margin-top: 34px;
      width: fit-content;
      color: #fff;
      text-align: center;
      font-family: 'Poppins', sans-serif !important;
      font-size: 18px;
      font-weight: 500;
      line-height: 18px;
      cursor: pointer;
      &:hover {
        background: #4748c8;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 991.5px) {
  .secondmain-section {
    .secondmain-box-data {
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;
    }
  }
}

@media (max-width: 768px) {
  .secondmain-section {
    .secondmain-box-data {
      // grid-template-columns: 1fr;
      grid-template-columns: repeat(6, 288px);
      //overflow-x: scroll;
      margin-bottom: 0px;
    }
  }
  .ourletsbegin-section {
    padding: 110px 0 140px;
    &::after {
      right: -52px;
      top: -66px;
      bottom: unset;
      width: 216px;
      height: 216px;
    }
    .ourletsbegin-text {
      h1 {
        font-size: 32px;
        margin: 28px 0;
      }
      button {
        margin-top: 50px;
      }
    }
  }

  .secondmain-section .container {
    position: relative;
  }
  .secondmain-section .container::after {
    content: ' ';
    background-color: #fff;
    height: 2px;
    position: absolute;
    bottom: 10px;
    margin: 0 15px;
    left: 0;
    right: 0;
    z-index: 0;
    border-radius: 2px !important;
  }
  .simplebar-scrollable-x {
    padding-bottom: 60px;
  }
  .simplebar-scrollbar {
    background-color: #8133f1;
    border-radius: 2px;
  }

  .simplebar-scrollbar::before {
    background-color: #8133f1 !important;
  }

  .simplebar-track {
    background-color: transparent;
  }
  .simplebar-track.simplebar-horizontal {
    height: 7px !important;
    bottom: 7.5px;
  }

  .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
    height: 7px;
  }

  // .secondmain-section .secondmain-box-data {
  //     padding-bottom: 65px;
  //     z-index: 2;
  //     position: relative;
  //     scrollbar-color: #8133F1 transparent;
  //         scrollbar-width: thin;
  // }

  // .secondmain-section .secondmain-box-data::-webkit-scrollbar {
  //     height: 7px !important;
  //     //scrollbar-width: thin !important;
  // }
  // .ourwinsection .container{
  //     position: relative;
  // }
  // .ourwinsection .container::after {
  //     content: " ";
  //     background-color: #fff;
  //     height: 2px;
  //     position: absolute;
  //     bottom: 2px;
  //     margin: 0 15px;
  //     left: 0;
  //     right: 0;
  //     z-index: 0;
  //     border-radius: 2px !important;
  // }

  // .secondmain-section .secondmain-box-data::-webkit-scrollbar-thumb {
  //     border-radius: 2px !important;
  //     background: #8133F1 !important;
  // }

  // /* Firefox */
  // .secondmain-section .secondmain-box-data::-moz-scrollbar {
  //     height: 7px !important;
  // //scrollbar-width: thin !important;
  //     //scrollbar-color: red;
  // }

  // .secondmain-section .secondmain-box-data::-moz-scrollbar-thumb {
  //     border-radius: 2px !important;
  //     background: #8133F1 !important;
  // }
}
