.enterprisegains-section {
  padding: 120px 0;
  .enterprisegains-section-data {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 26px;
    h1 {
      color: #4e4fdc;
      font-size: 52px;
      font-weight: 800;
      line-height: 66px;
      font-family: 'Epilogue', sans-serif !important;
    }
  }
}

@media (max-width: 768px) {
  .enterprisegains-section {
    padding: 90px 0 120px;
    .enterprisegains-section-data {
      grid-template-columns: repeat(1, 1fr);
      gap: 0;
      h1 {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 42px;
      }
    }
  }
}
