.implementation-hero-section {
  .main-section {
    // padding: 90px 0 72px;
    padding: 90px 0 120px;
  }
}

.whyus-section {
  margin-top: 130px;
  margin-bottom: 140px;
  h1 {
    color: #4e4fdc;
    font-size: 52px;
    font-weight: 800;
    line-height: 66px;
    font-family: 'Epilogue', sans-serif !important;
    margin-bottom: 34px;
    position: relative;
  }

  .subdata-card-row {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(2, 1fr);
    position: relative;
  }
}

@media (max-width: 991.5px) {
  .whyus-section {
    .subdata-card-row {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

@media (max-width: 768px) {
  .implementation-hero-section {
    .main-section {
      // padding: 68px 0 132px;
      padding: 68px 0 122px;
    }
  }
  .whyus-section {
    margin-top: 70px;
    h1 {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 71px;
    }
    .subdata-card-row {
      gap: 61px;
    }
  }
}
