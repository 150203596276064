// whoweare-section
.whoweare-section {
  margin-top: 130px;
  .whoweare-text {
    position: relative;
    margin-bottom: 74px;
    label {
      color: #737482;
      font-family: 'Epilogue', sans-serif !important;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 1.7px;
    }
    h1 {
      color: #4e4fdc;
      font-family: 'Epilogue', sans-serif !important;
      font-size: 32px;
      font-weight: 800;
      line-height: 40px;
      text-transform: uppercase;
      margin: 18px 0;
    }
    span {
      color: #44444d;
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
    }
  }
  .whoweare-box-data {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    .whoweare-box {
      border-radius: 8px;
      border: 2px solid #4e4fdc;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 28px 24px 40px;
      text-align: center;
      h2 {
        color: #4e4fdc;
        font-family: 'Epilogue', sans-serif !important;
        font-size: 16px;
        font-weight: 700;
        line-height: 24.5px;
      }
      span {
        color: #44444d;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
}

// whywithus-section
.whywithus-section {
  margin-top: 140px;
  padding: 130px 0 172px;
  position: relative;
  .container {
    position: relative;
  }
  .dot-img {
    position: absolute;
    right: -80px;
    top: -130px;
  }
  .whywithus-section-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    position: relative;
    .whywithus-text {
      position: relative;
      label {
        color: #737482;
        font-family: 'Epilogue', sans-serif !important;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 1.7px;
      }
      h1 {
        color: #e2e2e9;
        font-family: 'Epilogue', sans-serif !important;
        font-size: 36px;
        font-weight: 800;
        line-height: 40px;
        text-transform: uppercase;
        margin: 18px 0;
      }
      span {
        color: #b5b5c5;
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
      }
      h4 {
        color: #4e4fdc;
        font-family: 'Epilogue', sans-serif !important;
        font-size: 16px;
        font-weight: 700;
        line-height: 24.5px;
        margin-bottom: 16px;
      }
      .mar-top {
        margin-top: 57px;
      }
    }
    .paddi-left {
      padding-left: 84px;
      margin-top: 146px;
    }
  }
}

// serandsol-section
.serandsol-section {
  margin-top: 130px;
  padding-bottom: 86px;
  position: relative;
  overflow: hidden;
  .serandsol-bg {
    // position: absolute;
    // z-index: -2;
    // bottom: 0;
    // text-align: center;
    // width: 100%;

    position: relative;
    max-width: 100%;
    margin: 0 auto;
    height: 100%;
    z-index: -2;
    svg {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-53%) translateY(135%);
    }

    .about-line-animation {
      width: 2180px;
      // margin-left: -325px;
      margin-left: -200px;
      stroke-dasharray: 3900;
      stroke-dashoffset: 0;
      animation: aboutLineAnim 5s ease-in-out reverse;
    }

    @keyframes aboutLineAnim {
      to {
        stroke-dashoffset: 3900;
      }
    }
  }
  .serandsol-text {
    position: relative;
    label {
      color: #737482;
      font-family: 'Epilogue', sans-serif !important;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 1.7px;
    }
    h1 {
      color: #4e4fdc;
      font-family: 'Epilogue', sans-serif !important;
      font-size: 32px;
      font-weight: 800;
      line-height: 40px;
      text-transform: uppercase;
      margin: 18px 0;
    }
    span {
      color: #44444d;
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
    }
  }
  .serandsol-data-box {
    margin-top: 93px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    .serandsol-box {
      border-radius: 8px;
      background: linear-gradient(114deg, #4748c8, #26157e);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      display: grid;
      grid-template-columns: 3fr 2fr;
      padding: 59px 31px;
      align-items: flex-start;
      gap: 8px;
      min-height: 250px;
      .for-flex {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }
      h4 {
        color: #fff;
        font-family: 'Epilogue', sans-serif !important;
        font-size: 16px;
        font-weight: 700;
        line-height: 24.5px;
        margin-bottom: 20px;
      }
      span {
        color: #b5b5c5;
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
      }
      .deep-dive-link {
        a {
          color: #fff !important;
        }
        &:hover {
          svg {
            path {
              fill: #fff;
            }
            circle {
              stroke: #fff;
            }
          }
        }
      }
      img {
        margin: auto;
      }
      &:hover {
        background: #4748c8;
        img {
          display: none;
        }
      }
    }
  }
}

// vimiva-section
.vimiva-section {
  margin-top: 115px;
  padding: 80px 0 100px;
  .vimiva-text {
    position: relative;
    label {
      color: #737482;
      font-family: 'Epilogue', sans-serif !important;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 1.7px;
    }
    h1 {
      color: #4e4fdc;
      font-family: 'Epilogue', sans-serif !important;
      font-size: 36px;
      font-weight: 800;
      line-height: 40px;
      text-transform: uppercase;
      margin: 18px 0;
      padding-bottom: 0px;
    }
  }
  hr {
    margin: 60px 0;
    background-color: #aeaeef;
  }
  .accordian-section {
    .accordian-item {
      .accordian-head {
        .svg-icon {
          background-color: #aeaeef;
          svg {
            path {
              stroke: #000;
            }
          }
        }
        h3 {
          color: #aeaeef;
        }
      }
      .accordian-body {
        &::before {
          background-color: #aeaeef;
        }
        .accordian-body-data {
          padding-bottom: 0;
        }
        span {
          color: #b5b5c5;
          ul {
            li {
              color: #b5b5c5;
            }
          }
        }
        a {
          display: none;
        }
      }
    }
    .openitem {
      .accordian-head {
        .svg-icon {
          box-shadow: 0 0 5px 3px #926ca5;
        }
      }
    }
  }
}

// team-section
.team-section {
  margin-top: 100px;
  .team-text {
    position: relative;
    margin-bottom: 50px;
    label {
      color: #737482;
      font-family: 'Epilogue', sans-serif !important;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 1.7px;
    }
    h1 {
      color: #4e4fdc;
      font-family: 'Epilogue', sans-serif !important;
      font-size: 36px;
      font-weight: 800;
      line-height: 40px;
      text-transform: uppercase;
      margin: 18px 0;
    }
    span {
      color: #44444d;
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
    }
  }
  .team-slider {
    margin-top: 80px;
    padding: 0 35px;
    .team-slider-item {
      border-radius: 12px;
      background: linear-gradient(114deg, #4748c8, #26157e);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      padding: 48px 16px 38px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 200px;
        height: 200px;
        object-fit: cover;
      }
      h4 {
        color: #fff;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        line-height: 26px;
        margin-top: 18px;
      }
      label {
        color: #b5b5c5;
        text-align: center;
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
        margin-top: 8px;
        height: 34px;
      }
    }
  }
  .slick-initialized .slick-slide {
    padding: 0 12px;
  }
  .slick-prev:before,
  .slick-next:before {
    opacity: 1 !important;
  }
  .slick-prev:before {
    content: url('../../assets/images/Left_Arrow.svg') !important;
    margin-left: -18px;
  }
  .slick-next::before {
    content: url('../../assets/images/Right_Arrow.svg') !important;
    margin-right: -18px;
  }
}

.white-resources {
  .resources-section {
    background-color: transparent;
    margin-top: 110px;
    padding: 0;
    .animation-section-round1,
    .animation-section-round2,
    .animation-section-round3 {
      display: none;
    }
    .resources-row {
      margin-bottom: 60px;
      .resources-text {
        h1 {
          color: #4e4fdc;
          text-transform: capitalize;
        }
        span {
          color: #44444d;
        }
      }
      .resources-box-data {
        h1 {
          color: #4e4fdc;
        }
      }
    }
  }
}

// responsive
@media (max-width: 991.5px) {
  .whoweare-section {
    .whoweare-box-data {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .whywithus-section {
    .whywithus-section-row {
      .paddi-left {
        padding-left: 0;
      }
    }
  }
  .serandsol-section {
    .serandsol-data-box {
      grid-template-columns: repeat(1, 1fr);
    }
    .serandsol-bg svg {
      transform: translateX(-53%) translateY(145%);
    }
  }
}

@media (max-width: 768px) {
  .whoweare-section {
    margin-top: 70px;
    .whoweare-box-data {
      gap: 15px;
      .whoweare-box {
        padding: 28px 24px;
      }
    }
  }
  .whywithus-section {
    margin-top: 160px;
    padding: 109px 0 328px;
    position: relative;
    .dot-img {
      right: 10px;
      bottom: -315px;
      top: unset;
    }
    .whywithus-section-row {
      grid-template-columns: repeat(1, 1fr);
      gap: 57px;
      .whywithus-text {
        h1 {
          font-size: 32px;
        }
        .mar-top {
          margin-top: 49px;
        }
      }
      .paddi-left {
        padding-left: 0px;
        margin-top: 0;
      }
    }
  }
  .serandsol-section {
    margin-top: 90px;
    padding-bottom: 0;
    .serandsol-bg {
      display: none;
    }
    .serandsol-data-box {
      margin-top: 64px;
      gap: 40px;
      .serandsol-box {
        display: flex;
        flex-direction: column-reverse;
        gap: 56px;

        &:hover {
          background: #4748c8;
          img {
            display: block;
            margin: auto;
          }
        }
        h4 {
          margin-bottom: 0px;
        }
      }
    }
  }
  .vimiva-section {
    margin-top: 160px;
    .vimiva-text {
      h1 {
        font-size: 32px;
      }
    }
  }
  .team-section {
    .team-text {
      h1 {
        font-size: 32px;
      }
    }
  }
  .white-resources .resources-section .resources-row {
    margin-bottom: 0px;
  }
}

@media (max-width: 350px) {
  .team-section {
    .team-slider {
      zoom: 0.85;
    }
  }
}
