* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

ul {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

.deep-dive-link {
  margin-top: 19px;
  display: inline-flex;
  padding: 4px 0px;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  transition: 0.5s all;
  a {
    color: #4e4fdc;
    font-weight: 500;
    line-height: 20px;
    text-decoration-line: underline;
    cursor: pointer;
    transition: 0.5s all;
  }
  svg {
    transition: transform 0.2s ease-in-out;
  }
  &:hover {
    a {
      color: #9654f4;
    }
    svg {
      transform: scale(0.94);
      path {
        fill: #9654f4;
        transition: 0.5s all;
      }
      circle {
        stroke: #9654f4;
        transition: 0.5s all;
      }
    }
  }
}
.purpule {
  a {
    color: #9654f4;
  }
  &:hover {
    a {
      color: #4e4fdc !important;
    }
    svg {
      path {
        fill: #4e4fdc;
      }
      circle {
        stroke: #4e4fdc;
      }
    }
  }
}

/* .round-animation */
.round-animation {
  background-color: #091a2a;
  position: relative;
  overflow: hidden;
}
.animation-section-round1 {
  border-radius: 261px;
  background: #9654f4;
  filter: blur(250px);
  width: 261px;
  height: 261px;
  left: 0;
  position: absolute;
  animation: topbottom 4s infinite alternate;
}
@keyframes topbottom {
  0% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(100px, -370px);
  }
}
.animation-section-round2 {
  border-radius: 231px;
  background: #9654f4;
  filter: blur(150px);
  width: 231px;
  height: 231px;
  right: 0;
  position: absolute;
  animation: bottomtop 4s infinite alternate;
}
@keyframes bottomtop {
  0% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(78px, -300px);
  }
}
.animation-section-round3 {
  border-radius: 115px;
  background: #9654f4;
  filter: blur(100px);
  width: 115px;
  height: 115px;
  position: absolute;
  bottom: 0;
  left: 40%;
  animation: leftright 4s infinite alternate;
}
@keyframes leftright {
  0% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(275px, 50px);
  }
}

/* accordian-section */
.accordian-section {
  .accordian-item {
    margin-bottom: 12px;
    transition: all 0.5s ease;
    .accordian-head {
      display: grid;
      grid-template-columns: 1fr 35fr;
      gap: 12px;
      padding: 11px 0;
      cursor: pointer;
      transition: all 0.5s ease;
      overflow: unset !important;
      h3 {
        color: #4e4fdc;
        font-family: Epilogue;
        font-size: 16px;
        font-weight: 700;
        line-height: 24.5px;
        font-family: 'Epilogue', sans-serif !important;
        margin: 0;
        margin-top: 1px;
      }
      .svg-icon {
        background-color: #4e4fdc;
        border-radius: 50px;
        width: 25px;
        height: 25px;
        transition: all 0.5s ease;
        transform: rotate(0deg);
        box-shadow: none;
        overflow: unset !important;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 10px;
          height: 10px;
          transition: all 0.5s ease;
          box-shadow: none;
        }
      }
    }
    .accordian-body {
      display: flex;
      align-items: flex-start;
      grid-gap: 12px;
      gap: 12px;
      padding-left: 39px;
      justify-content: space-between;
      overflow: hidden;
      // height: 0px;
      max-height: 0;
      position: relative;
      transition: max-height 1s ease-in-out;
      // transition: all .5s ease;

      &::before {
        content: ' ';
        position: absolute;
        background: #4e4fdc;
        width: 4px;
        height: 100%;
        left: 11px;
      }
      ul {
        list-style: disc;
        margin-left: 25px;
        li {
          color: #44444d;
          font-size: 14px;
          font-weight: 400;
          line-height: 26px;
        }
      }
      span {
        color: #44444d;
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
        max-width: 600px;
      }
      .accordian-body-data {
        display: flex;
        grid-gap: 12px;
        gap: 25px;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
        padding-bottom: 25px;
      }
      .deep-dive-link {
        margin-top: 0;
        width: fit-content;
      }
    }
  }
  .openitem {
    .accordian-head {
      .svg-icon {
        transform: rotate(180deg);
        box-shadow: 0 0 5px 3px #debaf0;
        overflow: unset !important;
      }
    }
    .accordian-body {
      height: auto;
      max-height: 1000px;
    }
  }
}

.services-animation,
.implementation-animation,
.audit-animation,
.eiam-animation,
.iga-animation,
.pam-animation {
  zoom: 0.7;
}
.svg-img {
  width: 500px !important;
  margin-left: -70px;
  margin-top: -80px;
  height: 475px !important;
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 905px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 905px;
  }
}
@media (min-width: 1300px) {
  .container {
    max-width: 1014px !important;
  }
}

@media (max-width: 991.5px) {
  span {
    br {
      display: none !important;
    }
  }
}

@media (max-width: 768px) {
  .accordian-section {
    .accordian-item {
      .accordian-body {
        padding-left: 38px;
        .accordian-img {
          display: none;
        }
        &::before {
          left: 10px;
        }
      }
    }
    .openitem {
      .accordian-body {
        height: auto;
      }
    }
  }
  .services-animation,
  .implementation-animation,
  .audit-animation,
  .virtual-iam-animation,
  .opssupport-animation,
  .staff-animation,
  .svg-img,
  .eiam-animation,
  .iga-animation,
  .pam-animation {
    margin: auto;
  }
  .eiam-animation {
    zoom: 0.6;
  }
  .svg-img {
    width: fit-content;
  }
}

// Circle animation
.circle-svg-before-anim {
  opacity: 0;
}

.circle-svg path {
  opacity: 1;
  stroke-dasharray: 1200;
  stroke-dashoffset: 1200;
  animation: drawCircle 3s linear forwards;
}

@keyframes drawCircle {
  0% {
    stroke-dashoffset: 1200;
    fill: transparent;
  }

  100% {
    stroke-dashoffset: 0;
    fill: #695fff;
  }
}
