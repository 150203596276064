.services-hero-section {
  .main-section {
    padding: 90px 0px;
  }
}
.butmar {
  .container {
    button {
      margin-top: 74px;
    }
  }
}
.services-accordian-section {
  margin-top: 40px;
  .services-accordian-text {
    span {
      color: #44444d;
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
    }
  }
  .services-accordian {
    margin-top: 56px;
    margin-bottom: 100px;
  }
}
@media (max-width: 991.5px) {
  .butmar .readytouse-section .container button {
    margin-top: 0;
  }
}
@media (max-width: 768px) {
  .services-hero-section {
    .main-section {
      padding: 68px 0px 122px;
    }
  }
}
