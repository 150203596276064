.approach-section {
  position: relative;
  padding: 80px 0 87px;
  overflow: hidden;
  .container {
    position: relative;
  }
  .approach-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    align-items: start;
    position: relative;
    z-index: 1;
    img {
      width: 100%;
      padding-right: 50px;
    }
  }
  h1 {
    color: #4e4fdc;
    font-family: 'Epilogue', sans-serif !important;
    font-size: 32px;
    font-weight: 800;
    line-height: 40px;
    text-transform: capitalize;
    margin-bottom: 18px;
  }
  .span-text {
    span {
      color: #44444d;
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
    }
  }
  .approach-img {
    position: absolute;
    top: -162px;
    left: -82px;
    transform: rotate(180deg);
    svg {
      width: 491px;
      height: auto;
    }
  }
  .griddot {
    position: absolute;
    left: -170px;
    top: -70px;
  }
}

@media (max-width: 991.5px) {
  .approach-section {
    .griddot {
      right: 50px;

      display: none;
    }
  }
}

@media (max-width: 768px) {
  .approach-section {
    padding: 60px 0 107px;
    .approach-row {
      grid-template-columns: 1fr;
      img {
        padding-right: 0;
        order: 1;
      }
    }
    h1 {
      font-size: 32px;
      line-height: 40px;
    }
    .approach-img {
      display: none;
    }
    .griddot {
      right: 25px;
      bottom: -145px;
      top: unset;
      left: unset;
    }
  }
}
