.subdata-card-box {
  border-radius: 8px;
  border: 2px solid #4e4fdc;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  padding: 28px 24px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 15px;
  h4 {
    color: #4e4fdc;
    font-size: 16px;
    font-weight: 700;
    line-height: 24.5px;
    font-family: 'Epilogue', sans-serif !important;
    margin-bottom: 14px;
  }
  span {
    color: #44444d;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
  }
  svg {
    margin: auto;
    margin-top: 30px;
  }
}

@media (max-width: 768px) {
  .subdata-card-box {
    border: none;
    box-shadow: none;
    padding: 0px;
    display: flex;
    flex-direction: column-reverse;
    gap: 33px;
    h4 {
      margin-bottom: 9px;
    }
    svg {
      margin: 0;
    }
  }
}
