.eiam-hero-section {
  .main-section {
    padding: 90px 0 120px;
  }
}

.focus-bg-remove {
  .ourfocus-section {
    background-color: transparent;
    .animation-section-round1,
    .animation-section-round2,
    .animation-section-round3 {
      display: none;
    }
    .accordian-section {
      .accordian-item {
        .accordian-head {
          .svg-icon {
            background-color: #4e4fdc;
            svg {
              path {
                stroke: #fff;
              }
            }
          }
          h3 {
            color: #4e4fdc;
          }
        }
        .accordian-body {
          &::before {
            background-color: #4e4fdc;
          }
          .accordian-body-data {
            padding-bottom: 0;
          }
          span {
            color: #44444d;
            ul {
              li {
                color: #44444d;
              }
            }
          }
        }
      }
      .openitem {
        .accordian-head {
          .svg-icon {
            box-shadow: 0 0 5px 3px #debaf0;
          }
        }
      }
    }
  }
}

.bg-add {
  overflow: hidden;
  position: relative;
  .enterprisegains-section {
    background: #091a2a;
    .accordian-section {
      .accordian-item {
        .accordian-head {
          .svg-icon {
            background-color: #aeaeef;
            svg {
              path {
                stroke: #000;
              }
            }
          }
          h3 {
            color: #aeaeef;
          }
        }
        .accordian-body {
          &::before {
            background-color: #aeaeef;
          }
          .accordian-body-data {
            padding-bottom: 0;
          }
          span {
            color: #b5b5c5;
            ul {
              li {
                color: #b5b5c5;
              }
            }
          }
          a {
            display: none;
          }
        }
      }
      .openitem {
        .accordian-head {
          .svg-icon {
            box-shadow: 0 0 5px 3px #926ca5;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .eiam-hero-section {
    .main-section {
      // padding: 68px 0 57px;
      padding: 68px 0 122px;
    }
  }
}
