.ourfocus-section {
  padding: 120px 0;
  .ourfocus-section-data {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 26px;
    position: relative;
    h1 {
      color: #4e4fdc;
      font-size: 52px;
      font-weight: 800;
      line-height: 66px;
      font-family: 'Epilogue', sans-serif !important;
    }
    .accordian-section {
      .accordian-item {
        .accordian-head {
          .svg-icon {
            background-color: #aeaeef;
            svg {
              path {
                stroke: #000;
              }
            }
          }
          h3 {
            color: #aeaeef;
          }
        }
        .accordian-body {
          &::before {
            background-color: #aeaeef;
          }
          .accordian-body-data {
            padding-bottom: 0;
          }
          span {
            color: #b5b5c5;
            ul {
              li {
                color: #b5b5c5;
              }
            }
          }
          a {
            display: none;
          }
        }
      }
      .openitem {
        .accordian-head {
          .svg-icon {
            box-shadow: 0 0 5px 3px #926ca5;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .ourfocus-section {
    padding: 90px 0 120px;
    .ourfocus-section-data {
      grid-template-columns: repeat(1, 1fr);
      gap: 0;
      h1 {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 42px;
      }
    }
  }
}
