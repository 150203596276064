.ciam-page {
  .importance-section {
    &::before {
      right: 12px;
      left: unset;
    }
  }
}
.ciam-hero-section {
  .main-section {
    // padding: 90px 0 60px;
    padding: 90px 0 120px;
  }
}

.darkapproach {
  background-color: #091a2a;
  .approach-section {
    h1 {
      color: #e2e2e9;
    }
    .span-text {
      span {
        color: #b5b5c5;
      }
    }
    // .griddot{
    //    display: none;
    // }
  }
}

.conquering-section {
  .importance-section {
    &::before {
      left: 108px;
      right: unset;
      top: -28px;
    }
    .griddot {
      transform: rotate(90deg);
      //    bottom: -90px;
      bottom: -116px;

      // New
      transform-origin: left;
    }
  }
}

.ourvendor-section {
  padding: 80px 0 120px;
  border-top: 1px solid #4e4fdc;
  h1 {
    color: #4e4fdc;
    font-family: 'Epilogue', sans-serif !important;
    font-size: 52px;
    font-weight: 800;
    line-height: 66px;
    margin-bottom: 57px;
    text-align: center;
  }
  .vendor-img {
    display: flex;
    align-self: center;
    justify-content: space-around;
    img {
      height: 44px;
      object-fit: contain;
      width: 150px;
    }
  }
}

@media (max-width: 768px) {
  .ciam-hero-section {
    .main-section {
      // padding: 68px 0 80px;
      padding: 68px 0 122px;
    }
  }

  .ciam-page {
    .importance-section {
      &::before {
        right: -172px;
        top: -66px;
        // left: unset;
      }

      // New
      .griddot {
        bottom: -156px;
      }
    }
  }

  .darkapproach {
    background-color: #091a2a;
    .approach-section {
      padding: 154px 0 128px;
      .approach-img {
        left: -287px;
        top: -105px;
        display: block;
      }
    }
  }
  .conquering-section {
    .importance-section {
      &::before {
        left: -134px;
        right: unset;
        top: unset;
        bottom: 290px;
      }
      .griddot {
        transform: rotate(0);
        bottom: -171px;
      }
    }
  }
  .ourvendor-section {
    h1 {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 50px;
    }
  }
}
@media (max-width: 600px) {
  .ourvendor-section {
    .vendor-img {
      gap: 40px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: stretch;
      img {
        height: 34px;
        object-fit: contain;
        width: 70%;
        &:nth-child(1) {
          order: 1;
          margin-right: auto;
        }
        &:nth-child(2) {
          order: 4;
          margin-left: auto;
        }
        &:nth-child(3) {
          order: 2;
          margin-left: auto;
        }
        &:nth-child(4) {
          order: 3;
          margin-right: auto;
        }
      }
    }
  }
}
