.services-animation {
  width: 405.11px;
  height: 270.64px;
  position: relative;

  .status-position {
    position: absolute;
    left: 81px;
    top: 186px;
  }

  .virtualiam-position {
    position: absolute;
    left: 1.5px;
    top: 89.16px;
  }

  .speedline-position {
    position: absolute;
    top: 226.59px;
    left: 273.7px;
  }
  .monitor-position {
    position: absolute;
    top: 28.64px;
    left: 71.74px;
  }
  .shield-position {
    position: absolute;
    top: 77.55px;
    left: 208.11px;
  }
  .pulse-position {
    position: absolute;
    top: 95px;
    left: 232px;
  }
  .statusthree-position {
    position: absolute;
    top: 41px;
    left: 87px;
  }
  .gear-position {
    position: absolute;
    top: 2px;
    left: 291px;
  }
  .clock-position {
    position: absolute;
    top: 217px;
    left: 293px;
  }

  .animated-pulse {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: drawPath 4s linear infinite;
  }

  .static-pulse {
    opacity: 0;
    animation: showStatic 1s linear infinite;
  }

  @keyframes drawPath {
    0% {
      stroke-dashoffset: 1000;
    }
    50%,
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes showStatic {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .status-three-anim .sp-2 {
    animation: statusAnim1 4s linear infinite;
  }
  .status-three-anim .sp-3 {
    animation: statusAnim2 4s linear infinite;
  }

  .continuous-gear-anim {
    // transform-origin: center;
    animation: rotateGear 4s linear infinite;
  }

  @keyframes rotateGear {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .clock-hand-anim {
    transform-origin: center;
    animation: clockAnim 2s linear infinite;
  }

  @keyframes clockAnim {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .status-animation {
    animation: glowStatus 2s linear infinite;
  }

  @keyframes glowStatus {
    0% {
      stroke: #8889e8;
    }
    25% {
      stroke: #8d8ec0;
    }
    50% {
      stroke: #ffa900;
    }
    75% {
      stroke: #8889e8;
    }
    100% {
      stroke: #8889e8;
    }
  }
}
