.audit-animation {
  position: relative;
  width: 414.04px;
  height: 215.16px;
  /* top: 340px;
    left: 231px; */
  /* background-color: #091a2a; */
}

.lens {
  position: absolute;
  top: 111.14px;
  left: 1.5px;
  animation: translate 8s infinite forwards;
  /* animation-delay: 3s; */
}

@keyframes translate {
  0% {
    transform: translate(0);
  }

  20% {
    transform: translateY(-60.14px);
  }

  50%,
  100% {
    transform: translate(0);
  }
}

.computer {
  position: absolute;
  left: 84.26px;
  top: 1.5px;
}

.document {
  position: absolute;
  top: 14.32px;
  left: 162.81px;
}

.signal {
  position: absolute;
  top: 1.5px;
  left: 363px;
}

.signal .gradient-blur {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ffa900;
  position: absolute;
  top: 10px;
  left: 12px;
  filter: blur(3px);
  animation: first-color 8s infinite;
  animation-delay: 5s;
}

.signal .gradient-blur2 {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: transparent;
  position: absolute;
  top: 110px;
  left: 12px;
  filter: blur(3px);
  animation: third-color 8s infinite;
  animation-delay: 5s;
}

.signal-animation .first-round {
  /* background-image: radial-gradient(circle, #8133f1, #fdbd19); */
  /* stroke: #ffa900; */
  /* animation: first-color 8s infinite; */
  animation-delay: 5s;
}
.signal-animation .third-round {
  /* animation: third-color 8s infinite; */
  animation-delay: 5s;
}

@keyframes first-color {
  0% {
    background-color: #ffa900;
  }
  2%,
  10% {
    background-color: transparent;
  }
  15%,
  100% {
    background-color: #ffa900;
  }
}

@keyframes third-color {
  0% {
    background-color: transparent;
  }
  1%,
  8% {
    background-color: #ffa900;
    /* fill: transparent; */
  }
  10%,
  100% {
    background-color: transparent;
    /* fill: transparent; */
  }
}

@media (max-width: 600px) {
  .audit-animation {
    margin: 25px auto;
    width: 310px;
    height: 161.09px;
  }

  .audit-animation .lens {
    left: -14px;
  }
  .audit-animation .computer {
    left: 40px;
  }

  .audit-animation .computer img {
    width: 192.78px;
    height: 158.85px;
  }

  .audit-animation .document {
    top: 12px;
    left: 89.81px;
  }

  .audit-animation .document img {
    width: 111.14px;
    height: 126.09px;
  }

  .audit-animation .lens img {
    width: 81.07px;
    height: 54.55px;
  }

  .audit-animation .signal {
    top: -3px;
    left: 320px;
  }

  .audit-animation .gradient-blur {
    width: 25px;
    height: 25px;
    top: 8px;
    left: 6px;
  }

  .audit-animation .gradient-blur2 {
    width: 25px;
    height: 25px;
    top: 77px;
    left: 6px;
  }

  .audit-animation .signal svg {
    width: 36.85px;
    height: 110.55px;
  }
}
