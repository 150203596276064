.svg-img {
  position: relative;
  width: 605px;
  height: 542px;
}

.svg-img .cg1 {
  top: 313.89px;
  left: 364.01px;
  position: absolute;
}
.svg-img .cg2 {
  top: 384.12px;
  left: 226.68px;
  position: absolute;
}
.svg-img .cg3 {
  top: 300.9px;
  left: 77.04px;
  position: absolute;
}
.svg-img .cg4 {
  top: 151.66px;
  left: 66.21px;
  position: absolute;
}
.svg-img .cg5 {
  top: 74.05px;
  left: 215.51px;
  position: absolute;
}
.svg-img .cg6 {
  top: 158.5px;
  left: 360.01px;
  position: absolute;
}

.svg-img #center {
  position: absolute;
  top: 210.09px;
  left: 236.81px;
}

.svg-img .line1 {
  position: absolute;
  top: 165.33px;
  left: 291.51px;
}
.svg-img .line2 {
  position: absolute;
  top: 210.74px;
  left: 205.25px;
}
.svg-img .line3 {
  position: absolute;
  top: 215.14px;
  left: 205.25px;
}

.svg-img .group-hover {
  display: flex;
  flex-direction: column;
  width: 150px;
  height: 150px;
  align-items: center;
}

.svg-img .group-hover svg {
  transition: transform 0.5s ease-in-out;
}

.svg-img .group-hover .ct1 {
  text-align: center;
  transition: opacity 0.5s ease-in-out;
}

.svg-img .group-hover:hover svg {
  transform-origin: initial;
  transform: scale(1.3);
}

.svg-img .cg4 {
  transition: transform 0.5s ease-in-out;
}
.svg-img .cg5 {
  transition: transform 0.5s ease-in-out;
}

.svg-img .cg4:hover {
  transform: translateY(-30px);
}
.svg-img .cg5:hover {
  transform: translateY(-50px);
}

.svg-img .group-hover:hover path {
  stroke: #9654f4;
  transform-origin: initial;
}

.svg-img .group-hover .ct1 {
  color: #9654f4;
  margin-top: 20px;
  font-family: Epilogue;
  font-weight: 800;
  font-size: 13px;
  opacity: 0;
}

.svg-img .group-hover:hover .ct1 {
  opacity: 1;
}

.svg-img #center svg path {
  animation: switchColor 4s infinite forwards;
}

@keyframes switchColor {
  0% {
    stroke: #8889e8;
  }
  50% {
    stroke: #ffa900;
  }
  100% {
    stroke: #8889e8;
  }
}
