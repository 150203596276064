/* resources-section */
.resources-section {
  margin-top: 100px;
  padding: 100px 0 184px;
  .resources-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    .resources-text {
      position: relative;
      label {
        color: #737482;
        font-family: 'Epilogue', sans-serif !important;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 1.7px;
      }
      h1 {
        color: #e2e2e9;
        font-family: 'Epilogue', sans-serif !important;
        font-size: 36px;
        font-weight: 800;
        line-height: 40px;
        text-transform: uppercase;
        margin: 18px 0;
      }
      span {
        color: #b5b5c5;
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
      }
    }
    .resources-box-data {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 18px;
      .resources-box {
        display: flex;
        padding: 24px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
        border-radius: 124px;
        border: 2px solid #7172e3;
        height: 228px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
        &:hover {
          cursor: pointer;
          background-color: #ededfc;
          border-color: #ededfc;
          color: #4e4fdc;
          h1 {
            color: #4e4fdc;
          }
        }
        h1 {
          color: #8889e8;
          text-align: center;
          font-size: 24px;
          font-weight: 800;
          line-height: 40px;
          text-transform: capitalize;
          font-family: 'Epilogue', sans-serif !important;
        }
      }
    }
  }
  h3 {
    color: #b5b5c5;
    font-family: 'Epilogue', sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24.5px;
    margin: 0;
    margin-bottom: 25px;
  }
}

@media (max-width: 1301px) {
  .resources-section {
    .resources-row {
      .resources-box-data {
        .resources-box {
          padding: 10px;
          height: 205px;
        }
      }
    }
  }
}

@media (max-width: 991.5px) {
  .resources-section {
    .resources-row {
      grid-template-columns: repeat(1, 1fr);
      .resources-box-data {
        grid-template-columns: repeat(4, 1fr);
        .resources-box {
          height: 155px;
          h1 {
            font-size: 15px;
            line-height: 30px;
          }
          img {
            width: 60px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .resources-section {
    padding-bottom: 113px;
    .resources-row {
      grid-template-columns: repeat(1, 1fr);
      gap: 80px;
      .resources-text {
        h1 {
          font-size: 32px;
        }
      }
      .resources-box-data {
        grid-template-columns: repeat(1, 1fr);
        gap: 0;
        max-width: 345px;
        margin: auto;
        width: 100%;
        .resources-box {
          height: 200px;
          width: 200px;
          h1 {
            font-size: 24px;
          }
          &:nth-child(2),
          &:nth-child(4) {
            margin-left: auto;
            margin-top: -45px;
          }
          &:nth-child(3) {
            margin-top: -45px;
          }
        }
      }
    }
  }
}

@media (max-width: 350px) {
  .resources-box-data {
    zoom: 0.85;
  }
}
