.industries-desktop .industries-animation-2 {
  height: 403px;
  left: 0;
  position: relative;
  width: 387px;
}

.industries-mobile .industries-animation-mobile {
  height: 274px;
  left: 0;
  position: relative;
  width: 328px;
  top: 100px;
}

.factory {
  animation: factoryMoveTowardsCenter 4s linear infinite forwards;
}
.line-factory {
  transform-origin: unset;
  animation: factroyLine 4s linear infinite forwards;
}

@keyframes factoryMoveTowardsCenter {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(10px, 15px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes factroyLine {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-8deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.lighting {
  animation: lightingMoveTowardsCenter 4s linear infinite alternate;
}

.line-lighting {
  transform-origin: unset;
  animation: lightingLine 4s linear infinite alternate;
}

@keyframes lightingMoveTowardsCenter {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-5px, 25px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes lightingLine {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-12deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.dollar,
.setting {
  animation: dollarMove 4s linear infinite alternate;
}

@keyframes dollarMove {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0px, -40px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.medical {
  animation: medicalMoveTowardsCenter 4s linear infinite forwards;
}
.line-medical {
  transform-origin: center;
  animation: medicalLine 4s linear infinite forwards;
}

@keyframes medicalMoveTowardsCenter {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0px, 15px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes medicalLine {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
}

.notebook {
  animation: notebookMoveTowardsCenter 4s linear infinite forwards;
}
.line-notebook {
  transform-origin: center;
  animation: notebookLine 4s linear infinite forwards;
}

@keyframes notebookMoveTowardsCenter {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(20px, 25px);
  }
  100% {
    transform: translate(0, 0);
    /* transform: translate(20px, 25px); */
  }
}

@keyframes notebookLine {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.trolley {
  animation: trolleyMoveTowardsCenter 4s linear infinite forwards;
}
.line-trolley {
  transform-origin: center;
  animation: trolleyLine 4s linear infinite forwards;
}

@keyframes trolleyMoveTowardsCenter {
  0% {
    transform: translate(-20px, 5px);
  }
  50% {
    transform: translate(0, 0);
  }
  100% {
    /* transform: translate(0, 0); */
    transform: translate(-20px, 5px);
  }
}

@keyframes trolleyLine {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}
