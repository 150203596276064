.virtual-hero-section {
  .main-section {
    // padding: 90px 0 153px;
    padding: 90px 0 120px;
  }
}

@media (max-width: 768px) {
  .virtual-hero-section {
    .main-section {
      // padding: 68px 0 145px;
      padding: 68px 0 122px;
    }
  }
}
