// #setting1 {
//     transform-origin: 330.64 93.55;
//     animation: rotateSetting 4s infinite forwards;
// }

// #setting2 {

//     transform-origin: 297.62 122.22;
//     animation: rotateSetting 4s infinite forwards;
// }

@keyframes rotateSetting {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
