.svg-img-mobile {
  position: relative;
  width: 360px;
  height: 457px;
  margin: 0 auto;
}

.cg1 {
  top: 313.89px;
  left: 263.01px;
  position: absolute;
}
.cg2 {
  top: 384.12px;
  left: 132.68px;
  position: absolute;
}
.cg3 {
  top: 300.9px;
  left: 2.96px;
  position: absolute;
}
.cg4 {
  top: 151.66px;
  left: 0.79px;
  position: absolute;
}
.cg5 {
  top: 60.05px;
  left: 123.51px;
  position: absolute;
}
.cg6 {
  top: 158.5px;
  left: 245.01px;
  position: absolute;
}

#center {
  position: absolute;
  top: 210.09px;
  left: 121.81px;
}

.line1 {
  position: absolute;
  top: 165.33px;
  left: 176.51px;
}

.line2 {
  position: absolute;
  top: 210.74px;
  left: 90.25px;
}

.line3 {
  position: absolute;
  top: 215.14px;
  left: 90.25px;
}

.group-hover {
  display: flex;
  flex-direction: column;
  width: 100px;
  height: 120px;
  align-items: center;
}

.group-hover svg {
  transition: transform 0.5s ease-in-out;
}

.group-hover .ct1 {
  text-align: center;
  transition: opacity 0.5s ease-in-out;
}
.group-hover .ct1 {
  color: #9654f4;
  margin-top: 20px;
  font-family: Epilogue;
  font-weight: 800;
  font-size: 10px;
  opacity: 0;
}

#center svg path {
  animation: switchColor 4s infinite forwards;
}

@keyframes switchColor {
  0% {
    stroke: #8889e8;
  }
  50% {
    stroke: #ffa900;
  }
  100% {
    stroke: #8889e8;
  }
}

// @media screen and (min-width: 340px ) and (max-width: 390px ) {

//  .cg1 {
//     top: 313.89px;
//     left: 249.01px;
//     position: absolute;
// }
// .cg2 {
//     top: 384.12px;
//     left: 124.68px;
//     position: absolute;
// }
// .cg3 {
//     top: 300.9px;
//     left: -4.96px;
//     position: absolute;
// }
// .cg4 {
//     top: 151.66px;
//     left: -2.79px;
//     position: absolute;
// }
// .cg5 {
//     top: 60.05px;
//     left: 118.51px;
//     position: absolute;
// }
// .cg6 {
//     top: 158.5px;
//     left: 260.01px;
//     position: absolute;
// }

// #center {
//     position: absolute;
//     top: 210.09px;
//     left: 15.81px;
// }

// .line1 {
//     position: absolute;
//     top: 165.33px;
//     left: 17.51px;
// }

// .line2 {
//     position: absolute;
//     top: 210.74px;
//     left: 19.25px;
// }

// .line3 {
//     position: absolute;
//     top: 215.14px;
//     left: 14.25px;
// }

// }
@media screen and (min-width: 300px) and (max-width: 390px) {
  .svg-img-mobile {
    width: 290px;
    margin: 0 auto;
  }

  .cg1 {
    top: 313.89px;
    left: 216.01px;
    position: absolute;
  }
  .cg2 {
    top: 384.12px;
    left: 105.68px;
    position: absolute;
  }
  .cg3 {
    top: 300.9px;
    left: -23.96px;
    position: absolute;
  }
  .cg4 {
    top: 137.66px;
    left: -16.79px;
    position: absolute;
  }
  .cg5 {
    top: 60.05px;
    left: 93.51px;
    position: absolute;
  }
  .cg6 {
    top: 158.5px;
    left: 219.01px;
    position: absolute;
  }

  #center {
    position: absolute;
    top: 228.09px;
    left: 107.81px;
  }

  #center svg {
    width: 77px;
    height: 77px;
  }

  .line1 {
    position: absolute;
    top: 165.33px;
    left: 148.51px;
  }

  .line2 {
    position: absolute;
    top: 211.74px;
    left: 60.25px;
  }

  .line3 {
    position: absolute;
    top: 210.14px;
    left: 59.25px;
  }
}
