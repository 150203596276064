.opssupport-main {
  .main-section {
    // padding: 90px 0 147px;
    padding: 90px 0 120px;
  }
}

@media (max-width: 768px) {
  .opssupport-main {
    .main-section {
      // padding: 68px 0 119px;
      padding: 68px 0 122px;
      .main-section-data .animationimg {
        text-align: unset;
      }
    }
  }
}
