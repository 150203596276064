footer {
  background-color: #091a2a;
  .footer-top-row {
    padding: 20px 0;
    border-bottom: solid 1px #ededfc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      color: #b5b5c5;
      font-size: 16px;
      font-weight: 800;
      line-height: 24px;
      letter-spacing: 0.2px;
      font-family: 'Epilogue', sans-serif !important;
      margin-right: 10px;
    }
    a svg {
      margin-left: 20px;
    }
  }
  .footer-center-row {
    padding: 50px 0;
    border-bottom: solid 1px #ededfc;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    h3 {
      color: #b5b5c5;
      font-size: 16px;
      font-weight: 800;
      line-height: 24px;
      letter-spacing: 0.2px;
      font-family: 'Epilogue', sans-serif !important;
      margin-bottom: 8px;
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        margin: 0;
        padding: 0;
        margin-bottom: 4px;
        list-style: none;
        a {
          color: #b5b5c5;
          font-size: 12px;
          font-weight: 500;
          line-height: 26px;
          text-decoration: none;
        }
      }
    }
  }
  .footer-bottom-row {
    padding: 34px 0 45px;
    ul {
      display: flex;
      gap: 32px;
      align-items: center;
      margin-bottom: 10px;
      li {
        list-style: none;
        a {
          color: #b5b5c5;
          font-size: 12px;
          font-weight: 500;
          line-height: 26px;
          text-decoration: none;
        }
      }
    }
    span {
      color: #b5b5c5;
      font-size: 12px;
      font-weight: 500;
      line-height: 26px;
    }
  }
}

@media (max-width: 768px) {
  footer {
    .container {
      padding: 0;
    }
    .footer-top-row {
      padding-bottom: 60px;
      padding-right: 15px;
      padding-left: 15px;
      flex-direction: column;
      align-items: start;
      gap: 36px;
    }
    .footer-center-row {
      padding-right: 15px;
      padding-left: 15px;
      gap: 40px;
      flex-direction: column;
      .mobilenone {
        display: none;
      }
    }
    .footer-bottom-row {
      padding: 34px 0 45px;
      padding-right: 15px;
      padding-left: 15px;
      ul {
        flex-direction: column;
        align-items: start;
        gap: 0;
      }
    }
  }
}
