/* hero-section */
.hero-section {
  padding: 119px 10px 218px;
  .hero-section-data {
    text-align: center;
    max-width: 853px;
    position: relative;
    z-index: 2;
    margin: auto;
    label {
      color: #9654f4;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      line-height: 30px;
    }
    h1 {
      color: #e2e2e9;
      text-align: center;
      font-family: 'Epilogue', sans-serif !important;
      font-size: 56px;
      font-weight: 800;
      line-height: 66px;
      margin: 15px 0 25px;
    }
    span {
      color: #b5b5c5;
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
    }
    button {
      border-radius: 8px;
      background: #4e4fdc;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      outline: 0;
      border: 0;
      display: flex;
      padding: 24px 36px;
      align-items: flex-start;
      gap: 8px;
      margin: auto;
      margin-top: 56px;
      width: fit-content;
      color: #fff;
      text-align: center;
      font-family: 'Poppins', sans-serif !important;
      font-size: 18px;
      font-weight: 500;
      line-height: 18px;
      cursor: pointer;
      &:hover {
        background: #4748c8;
        cursor: pointer;
      }
    }
  }
}

/* marque-section */
.marque-section {
  margin-top: 106px;
  .rfm-child {
    margin-left: 22px;
  }
}

/* step-section */
.step-section {
  margin-top: 70px;
  position: relative;
  overflow: hidden;
  padding-bottom: 110px;
  .step-text {
    max-width: 710px;
    span {
      color: #44444d;
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
      b {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
  .step-box-data {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    margin-top: 80px;
    .step-box {
      display: flex;
      padding: 24px 24px 28px 24px;
      flex-direction: column;
      align-items: center;
      gap: 30px;
      position: relative;
      border-radius: 8px;
      background: #ededfc;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
      cursor: pointer;
      transition: 0.2s all ease-in;
      h4 {
        color: #1e1f20;
        text-align: center;
        margin: 0;
        font-family: 'Epilogue', sans-serif !important;
        font-size: 16px;
        font-weight: 700;
        line-height: 24.5px;
      }
      .step-box-overlay {
        border-radius: 8px;
        background: #4748c8;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 24px;
        opacity: 0;
        visibility: hidden;
        z-index: -1;
        cursor: pointer;
        transition: 0.2s all ease-in;
        span {
          color: #e2e2e9;
          font-size: 16px;
          font-weight: 400;
          line-height: 26px;
        }
      }
      &:hover .step-box-overlay {
        opacity: 1;
        visibility: visible;
        z-index: 2;
      }
    }
  }
  .step-bg {
    // position: absolute;
    // z-index: -2;
    // bottom: 4px;
    // text-align: center;
    // width: 100%;

    position: relative;
    max-width: 100%;
    margin: 0 auto;
    height: 100%;
    z-index: -2;
    svg {
      position: absolute;
      left: 44%;
      bottom: 0;
      transform: translateX(-49%) translateY(150%);
    }
  }
  .step-bg-mobile {
    display: none;
  }
}

#home-draw-animation {
  stroke-dasharray: 3600;
  stroke-dashoffset: 0;
  animation: draw 5s linear reverse;
}

@keyframes draw {
  to {
    stroke-dashoffset: 3600;
  }
}

/* our-services-section */
.our-services-section {
  margin-top: 170px;
  padding: 170px 0 270px;
  .our-services-text {
    position: relative;
    label {
      color: #737482;
      font-family: 'Epilogue', sans-serif !important;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 1.7px;
    }
    h1 {
      color: #e2e2e9;
      font-family: 'Epilogue', sans-serif !important;
      font-size: 36px;
      font-weight: 800;
      line-height: 40px;
      text-transform: uppercase;
      margin: 18px 0;
    }
    span {
      color: #b5b5c5;
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
    }
  }
  .our-services-box-data {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    margin-top: 55px;
    .our-services-box {
      border-radius: 8px;
      background: linear-gradient(114deg, #103259, #26157e);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      display: flex;
      padding: 78px 24px 52px;
      flex-direction: column;
      align-items: center;
      gap: 55px;
      flex-shrink: 0;
      position: relative;
      cursor: pointer;
      transition: 0.2s all ease-in;
      svg {
        height: 86px;
      }
      h4 {
        color: #fff;
        text-align: center;
        font-family: 'Epilogue', sans-serif !important;
        font-size: 16px;
        font-weight: 700;
        line-height: 24.5px;
      }
      .our-services-box-overlay {
        border-radius: 8px;
        background: #9654f4;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 40px 24px 32px;
        opacity: 0;
        visibility: hidden;
        z-index: -1;
        cursor: pointer;
        transition: 0.2s all ease-in;
        span {
          color: #fff;
          font-size: 16px;
          font-weight: 400;
          line-height: 26px;
        }
        .deep-dive-link {
          display: none;
        }
        a {
          color: #fff;
          font-size: 14px;
          font-weight: 700;
          line-height: 26px;
          display: flex;
          gap: 15px;
          align-items: center;
          text-decoration: none;
          svg {
            height: auto;
          }
        }
      }
      &:hover .our-services-box-overlay {
        opacity: 1;
        visibility: visible;
        z-index: 2;
      }
    }
  }
}

/* solution-section */
.solution-section {
  margin-top: 90px;
  .solution-text {
    position: relative;
    margin-bottom: 50px;
    label {
      color: #737482;
      font-family: 'Epilogue', sans-serif !important;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 1.7px;
    }
    h1 {
      color: #4e4fdc;
      font-family: 'Epilogue', sans-serif !important;
      font-size: 36px;
      font-weight: 800;
      line-height: 40px;
      text-transform: uppercase;
      margin: 18px 0;
    }
    span {
      color: #44444d;
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
    }
  }
}

/* about-section */
.about-section {
  margin-top: 90px;
  padding: 90px 0 235px;
  .about-text {
    position: relative;
    margin-bottom: 65px;
    label {
      color: #737482;
      font-family: 'Epilogue', sans-serif !important;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 1.7px;
    }
    h1 {
      color: #e2e2e9;
      font-family: 'Epilogue', sans-serif !important;
      font-size: 36px;
      font-weight: 800;
      line-height: 40px;
      text-transform: uppercase;
      margin: 18px 0;
    }
    span {
      color: #b5b5c5;
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
    }
  }
  h3 {
    color: #b5b5c5;
    font-family: 'Epilogue', sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24.5px;
    margin: 0;
    margin-bottom: 25px;
  }
  .about-box-data {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    .about-box {
      display: flex;
      padding: 24px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
      border-radius: 124px;
      border: 2px solid #7172e3;
      height: 230px;
      h1 {
        color: #8889e8;
        text-align: center;
        font-size: 32px;
        font-weight: 800;
        line-height: 40px;
        text-transform: uppercase;
        font-family: 'Epilogue', sans-serif !important;
      }
      span {
        color: #b5b5c5;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
      }
    }
  }
}

/* industries-section */
.industries-section {
  margin-top: 90px;
  .industries-text {
    position: relative;
    margin-bottom: 50px;
    label {
      color: #737482;
      font-family: 'Epilogue', sans-serif !important;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 1.7px;
    }
    h1 {
      color: #4e4fdc;
      font-family: 'Epilogue', sans-serif !important;
      font-size: 36px;
      font-weight: 800;
      line-height: 40px;
      text-transform: uppercase;
      margin: 18px 0;
    }
    span {
      color: #44444d;
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
    }
  }
}

/* casestudies-section */
.casestudies-section {
  margin-top: 146px;
  .casestudies-text {
    position: relative;
    margin-bottom: 50px;
    label {
      color: #737482;
      font-family: 'Epilogue', sans-serif !important;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 1.7px;
    }
    h1 {
      color: #4e4fdc;
      font-family: 'Epilogue', sans-serif !important;
      font-size: 36px;
      font-weight: 800;
      line-height: 40px;
      text-transform: uppercase;
      margin: 18px 0;
    }
    span {
      color: #44444d;
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
    }
  }
  .casestudies-slider {
    margin-top: 80px;
    padding: 0 35px;
    .casestudies-slider-item {
      border-radius: 12px;
      background: linear-gradient(114deg, #4748c8, #26157e);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      padding: 48px 16px 38px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      h3 {
        color: #fff;
        text-align: center;
        font-family: 'Epilogue', sans-serif !important;
        font-size: 16px;
        font-weight: 700;
        line-height: 24.5px;
        margin-bottom: 6px;
      }
      svg {
        margin-bottom: 18px;
        width: 100%;
        min-height: 18px;
      }
      span {
        color: #b5b5c5;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        min-height: 110px;
      }
      h4 {
        color: #fff;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        line-height: 26px;
        margin-top: 18px;
      }
      label {
        color: #b5b5c5;
        text-align: center;
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
        margin-top: 8px;
        height: 34px;
      }
      > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
  .slick-initialized .slick-slide {
    padding: 0 12px;
  }
  .slick-prev:before,
  .slick-next:before {
    opacity: 1 !important;
  }
  .slick-prev:before {
    content: url('../../assets/images/Left_Arrow.svg') !important;
    margin-left: -18px;
  }
  .slick-next::before {
    content: url('../../assets/images/Right_Arrow.svg') !important;
    margin-right: -18px;
  }
}

/* letsbegin-section */
.letsbegin-section {
  margin-top: 163px;
  padding: 130px 0 165px;
  .letsbegin-text {
    position: relative;
    label {
      color: #737482;
      font-family: 'Epilogue', sans-serif !important;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 1.7px;
    }
    h1 {
      color: #4e4fdc;
      font-family: 'Epilogue', sans-serif !important;
      font-size: 36px;
      font-weight: 800;
      line-height: 40px;
      text-transform: uppercase;
      margin: 18px 0;
    }
    span {
      color: #b5b5c5;
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
    }
    button {
      border-radius: 8px;
      background: #4e4fdc;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      outline: 0;
      border: 0;
      display: flex;
      padding: 24px 36px;
      align-items: flex-start;
      gap: 8px;
      margin-top: 34px;
      width: fit-content;
      color: #fff;
      text-align: center;
      font-family: 'Poppins', sans-serif !important;
      font-size: 18px;
      font-weight: 500;
      line-height: 18px;
      cursor: pointer;
      &:hover {
        background: #4748c8;
        cursor: pointer;
      }
    }
  }
}

/* responsive */
@media (min-width: 1901px) {
  .step-section {
    .step-bg {
      svg {
        left: 55%;
      }
    }
  }
}

@media (max-width: 1301px) {
  .about-section {
    .about-box-data {
      .about-box {
        padding: 10px;
        height: 205px;
      }
    }
  }
}

@media (max-width: 991.5px) {
  .hero-section {
    padding: 119px 10px 200px;
    .hero-section-data {
      h1 {
        font-size: 36px;
        line-height: 40px;
      }
    }
  }
  .step-section {
    .step-box-data {
      .step-box {
        svg {
          width: 120px;
          height: auto;
        }
        .step-box-overlay {
          padding: 10px;
        }
      }
    }
    .step-bg svg {
      transform: translateX(-49%) translateY(135%);
    }
  }
  .our-services-section {
    .our-services-box-data {
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;
    }
  }
  .about-section {
    .about-box-data {
      .about-box {
        height: 155px;
        h1 {
          font-size: 20px;
          line-height: 30px;
        }
        span {
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .hero-section {
    padding: 105px 0px 157px;
    .hero-section-data {
      label {
        font-size: 14px;
        line-height: 20px;
      }
      h1 {
        font-size: 36px;
        line-height: 40px;
        margin: 36px 0 56px;
      }
      span br {
        display: none;
      }
      button {
        margin-top: 82px;
      }
    }
  }
  .marque-section {
    margin-top: 94px;
    .text-slide {
      zoom: 0.6;
    }
  }
  .step-section {
    padding-bottom: 0;
    .step-bg {
      display: none;
    }
    .step-bg-mobile {
      position: relative;
      max-width: 100%;
      margin: 0 auto;
      height: 100%;
      z-index: -2;
      display: block;
      svg {
        position: absolute;
        top: -70px;
      }

      #home-mobile-draw-animation-1,
      #home-mobile-draw-animation-2 {
        stroke-dasharray: 250;
        stroke-dashoffset: 0;
        animation: home-mobile-draw 2s linear reverse;
      }

      @keyframes home-mobile-draw {
        to {
          stroke-dashoffset: 250;
        }
      }
    }
    .step-box-data {
      grid-template-columns: repeat(1, 1fr);
      gap: 44px;
      position: relative;
      margin-top: 62px;
      .step-box {
        padding: 0;
        padding-top: 40px;
        svg {
          width: 168px;
        }
        .step-box-overlay {
          position: unset;
          opacity: 1;
          visibility: visible;
          z-index: 1;
          border-radius: 0 0 8px 8px;
          height: auto;
          padding: 24px;
        }
      }
      // &::before{
      //     content: ' ';
      //     background-color: #9654F4;
      //     width: 3px;
      //     height: 95%;
      //     position: absolute;
      //     left: 50%;
      //     top: 10px;
      // }
    }
  }
  .our-services-section {
    margin-top: 138px;
    padding: 109px 0 107px;
    .our-services-text {
      h1 {
        font-size: 32px;
      }
    }
    .our-services-box-data {
      // grid-template-columns: repeat(1, 1fr);
      grid-template-columns: repeat(6, 312px);
      // overflow-x: scroll;
      gap: 24px;

      [data-aos][data-aos][data-aos-duration='1000'],
      body[data-aos-duration='1000'] [data-aos] {
        opacity: 1;
        transform: translateZ(0px);
      }
      .our-services-box {
        gap: 12px;
        padding: 24px;
        align-items: flex-start;
        h4 {
          text-align: left;
          min-height: 49px;
        }
        svg {
          margin: 40px auto;
        }
        .our-services-box-overlay {
          position: unset;
          background: transparent;
          padding: 0;
          box-shadow: none;
          opacity: 1;
          visibility: visible;
          z-index: 2;
          gap: 40px;
          height: auto;
          span {
            min-height: 156px;
          }
          .deep-dive-link {
            display: flex;
            a {
              display: flex;
              color: #9654f4;
              text-decoration-line: underline;
              font-size: 16px;
            }
            svg {
              margin: 0 !important;
              height: fit-content;
            }
          }
          a {
            display: none;
          }
        }
      }
    }
  }
  .solution-section {
    margin-top: 80px;
    .solution-text {
      h1 {
        font-size: 32px;
      }
    }
  }
  .about-section {
    padding-bottom: 194px;
    .about-text {
      h1 {
        font-size: 32px;
      }
    }
    .about-box-data {
      grid-template-columns: repeat(1, 1fr);
      gap: 0;
      max-width: 345px;
      margin: auto;
      .about-box {
        height: 200px;
        width: 200px;
        h1 {
          font-size: 32px;
        }
        span {
          font-size: 14px;
        }
        &:nth-child(2),
        &:nth-child(4) {
          margin-left: auto;
          margin-top: -45px;
        }
        &:nth-child(3) {
          margin-top: -45px;
        }
      }
    }
    h3 {
      display: none;
    }
  }
  .industries-section {
    .industries-text {
      h1 {
        font-size: 32px;
      }
    }
  }
  .casestudies-section {
    margin-top: 96px;
    // .casestudies-slider .casestudies-slider-item {
    //     min-height: 380px;
    // }

    .casestudies-text {
      h1 {
        font-size: 32px;
      }
    }
  }
  .letsbegin-section {
    margin-top: 133px;
    padding: 100px 0 140px;
    .letsbegin-text {
      h1 {
        font-size: 32px;
      }
      button {
        margin-top: 40px;
      }
    }
  }

  //
  // .our-services-section .our-services-box-data {
  //     padding-bottom: 65px;
  //     z-index: 2;
  //     position: relative;
  //     scrollbar-color: #8133F1 transparent;
  //         scrollbar-width: thin;
  // }

  // .our-services-section .our-services-box-data::-webkit-scrollbar {
  //     height: 7px !important;
  //     //scrollbar-width: thin !important;
  // }
  .ourservice-card-section .container {
    position: relative;
  }
  .ourservice-card-section .container::after {
    content: ' ';
    background-color: #fff;
    height: 2px;
    position: absolute;
    bottom: 10px;
    margin: 0 15px;
    left: 0;
    right: 0;
    z-index: 0;
    border-radius: 2px !important;
  }
  .simplebar-scrollable-x {
    padding-bottom: 60px;
  }
  .simplebar-scrollbar {
    background-color: #8133f1;
    border-radius: 2px;
  }

  .simplebar-scrollbar::before {
    background-color: #8133f1 !important;
  }

  .simplebar-track {
    background-color: transparent;
  }
  .simplebar-track.simplebar-horizontal {
    height: 7px !important;
    bottom: 7.5px;
  }

  .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
    height: 7px;
  }

  // .our-services-section .our-services-box-data::-webkit-scrollbar-thumb {
  //     border-radius: 2px !important;
  //     background: #8133F1 !important;
  // }

  // /* Firefox */
  // .our-services-section .our-services-box-data::-moz-scrollbar {
  //     height: 7px !important;
  // //scrollbar-width: thin !important;
  //     //scrollbar-color: red;
  // }

  // .our-services-section .our-services-box-data::-moz-scrollbar-thumb {
  //     border-radius: 2px !important;
  //     background: #8133F1 !important;
  // }
}

@media (max-width: 350px) {
  .about-box-data {
    zoom: 0.85;
  }
  .casestudies-section {
    .casestudies-slider {
      zoom: 0.85;
    }
  }
}

.simplebar-track.simplebar-vertical {
  display: none !important;
}
