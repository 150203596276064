.virtual-iam-animation {
  width: 261.2px;
  height: 164.2px;
  /* background-color: #091a2a; */
  position: relative;
  /* top: 351px;
    left: 229px; */
}

.virtual-iam-animation .clock {
  position: absolute;
  height: 53.2px;
  width: 53.2px;
  top: 111px;
  left: 208px;
}

.clock .clock-tick {
  animation: rotate-tick 8s linear infinite;
  transform-origin: center;
}

.left-part .animation-line {
  animation: translateLine 6s infinite forwards;
}

.animation-boxes .animation-box-1 {
  animation: scaleBox 6s infinite forwards;
}

.animation-boxes .animation-box-2 {
  animation: scaleBox2 6s infinite forwards;
}

.eye path {
  animation: fillColor 5s infinite forwards;
  stroke: #8889e8;
}

.certification path {
  animation: fillColor2 5s infinite forwards;
  /* animation-delay: s; */
  stroke: #8889e8;
}
@keyframes fillColor {
  0% {
    stroke: #8889e8;
  }
  20% {
    stroke: #ffa900;
  }

  50%,
  100% {
    stroke: #8889e8;
  }
}
@keyframes fillColor2 {
  0% {
    stroke: #8889e8;
  }
  20% {
    stroke: #ffa900;
  }

  30%,
  100% {
    stroke: #8889e8;
  }
}

@keyframes scaleBox {
  0% {
    width: 50px;
  }
  25% {
    width: 25px;
  }

  75%,
  100% {
    width: 50px;
  }
}

@keyframes scaleBox2 {
  0% {
    width: 25px;
  }
  25% {
    width: 50px;
  }
  75%,
  100% {
    width: 25px;
  }
}

@keyframes translateLine {
  0% {
    transform: translateY(4px);
  }

  25% {
    transform: translateY(-4px);
  }

  75%,
  100% {
    transform: translateY(4px);
  }
}

@keyframes rotate-tick {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
