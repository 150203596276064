.resources-hero-section {
  .main-section {
    // padding: 140px 0px 123px;
    padding: 90px 0px 120px;
  }
}

.resources-content {
  padding-bottom: 152px;
  padding-top: 37px;
  position: relative;
  overflow: hidden;

  .resources-bg-mobile {
    display: none;
  }

  &::before {
    content: ' ';
    width: 261px;
    height: 261px;
    border-radius: 261px;
    background: #9437ff;
    filter: blur(250px);
    position: absolute;
    top: -48px;
    right: -14px;
  }
  &::after {
    content: ' ';
    width: 183px;
    height: 183px;
    border-radius: 183px;
    background: #9437ff;
    filter: blur(250px);
    position: absolute;
    bottom: 855px;
    left: -59px;
  }
  .resources-bg {
    // position: absolute;
    // bottom: 0;
    // max-width: 3000px;
    // left: calc(100% - 0px);
    // bottom: -555px;
    // margin: 0 auto;

    // position: absolute;
    // max-width: 3000px;
    // right: calc(100% - 400px);
    // left: 0;
    // bottom: -555px;
    // margin: 0 auto;
    position: relative;
    max-width: 100%;
    margin: 0 auto;
    height: 100%;

    svg {
      position: absolute;
      left: 50%;
      bottom: -50%;
      transform: translateX(-50%) translateY(106%);
    }

    svg .line-animation {
      stroke-dasharray: 5400;
      stroke-dashoffset: 0;
      animation: lineThrough 7s ease-in-out reverse;
    }

    @keyframes lineThrough {
      to {
        stroke-dashoffset: 5400;
      }
    }
  }
  .container {
    position: relative;
  }
  .topimg {
    position: absolute;
    top: -43px;
    left: -170px;
  }
  .centerimg {
    position: absolute;
    top: -100px;
    bottom: 0;
    right: -195px;
    margin: auto 0;
    display: flex;
    align-items: center;
    &::after {
      content: ' ';
      width: 261px;
      height: 261px;
      border-radius: 261px;
      background: #9437ff;
      filter: blur(250px);
      position: absolute;
      bottom: -23px;
      right: 107px;
    }
  }
  .resources-content-row {
    position: relative;
    display: grid;
    grid-template-columns: 4fr 3fr;
    gap: 24px;
    .resources-content-box {
      border-radius: 4px;
      background: #ededfc;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
      padding: 49px 42px;
      display: flex;
      flex-direction: column;
      margin-top: 83px;
      margin-right: auto;
      width: fit-content;
      cursor: pointer;
      &:hover {
        background: #efe6fd;
      }
      img {
        margin-bottom: 24px;
        height: 210px;
        object-fit: cover;
        width: 100%;
      }
      label {
        color: #737482;
        font-family: 'Epilogue', sans-serif !important;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 1.7px;
      }
      h1 {
        color: #4e4fdc;
        font-family: 'Epilogue', sans-serif !important;
        font-size: 32px;
        font-weight: 800;
        line-height: 40px;
        text-transform: uppercase;
        margin: 18px 0;
      }
      span {
        color: #44444d;
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
      }
    }
  }
  .reverse-col {
    grid-template-columns: 3fr 4fr;
  }
}

@media (max-width: 991.5px) {
  .resources-content {
    .resources-content-row {
      grid-template-columns: 4fr 1fr;
    }
    .reverse-col {
      grid-template-columns: 1fr 4fr;
    }
  }
}

@media (max-width: 768px) {
  .resources-hero-section {
    .main-section {
      // padding: 68px 0px 184px;
      padding: 68px 0px 122px;
    }
  }
  .resources-content {
    .container {
      // &::before {
      //     content: " ";
      //     background-color: #9654F4;
      //     width: 3px;
      //     height: 67%;
      //     position: absolute;
      //     left: 50%;
      //     top: 190px;
      // }

      .resources-bg-mobile {
        position: relative;
        max-width: 100%;
        margin: 0 auto;
        height: 100%;
        z-index: -2;
        display: flex;
        justify-content: center;
        svg {
          position: absolute;
          top: -70px;
        }

        #resources-mobile-draw-animation-1,
        #resources-mobile-draw-animation-2 {
          stroke-dasharray: 250;
          stroke-dashoffset: 0;
          animation: home-mobile-draw 2s linear reverse;
        }

        @keyframes home-mobile-draw {
          to {
            stroke-dashoffset: 250;
          }
        }
      }
    }
    &::before {
      top: 733px;
      right: -94px;
    }
    &::after {
      bottom: -95px;
      left: -88px;
      width: 239px;
      height: 239px;
      border-radius: 239px;
      z-index: -1;
    }
    .topimg,
    .centerimg,
    .resources-bg {
      display: none;
    }
    .resources-content-row {
      grid-template-columns: 1fr;
      position: relative;
      .resources-content-box {
        padding: 16px 16px 40px;
        margin-top: 96px;
        img {
          height: 232px;
        }
      }
      div {
        display: none;
      }
      .deep-dive-link {
        display: inline-flex !important;
      }
    }
    .reverse-col {
      grid-template-columns: 1fr;
    }
  }
}
