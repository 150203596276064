.secondmain-section {
  padding: 90px 0 120px;
  .secondmain-section-data {
    position: relative;
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 24px;
    label {
      color: #9654f4;
      font-size: 18px;
      font-weight: 500;
      line-height: 30px;
    }
    h1 {
      color: #e2e2e9;
      font-family: 'Epilogue', sans-serif !important;
      font-size: 56px;
      font-weight: 800;
      line-height: 66px;
      margin: 20px 0;
    }
    span {
      color: #b5b5c5;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
    }
    .animationimg {
      display: flex;
      justify-content: center;
      svg {
        width: 229px;
        height: auto;
      }
    }
  }
  .secondmain-accordian {
    margin-top: 70px;
    .accordian-section {
      .accordian-item {
        .accordian-head {
          .svg-icon {
            background-color: #aeaeef;
            svg {
              path {
                stroke: #000;
              }
            }
          }
          h3 {
            color: #aeaeef;
          }
        }
        .accordian-body {
          &::before {
            background-color: #aeaeef;
          }
          .accordian-body-data {
            padding-bottom: 0;
          }
          span {
            color: #b5b5c5;
            ul {
              li {
                color: #b5b5c5;
              }
            }
          }
          a {
            display: none;
          }
        }
      }
      .openitem {
        .accordian-head {
          .svg-icon {
            box-shadow: 0 0 5px 3px #926ca5;
          }
        }
      }
    }
  }
}

.talktous-section {
  position: relative;
  overflow: hidden;
  &::before {
    content: ' ';
    border-radius: 216px;
    background: #9437ff;
    width: 216px;
    height: 216px;
    right: -61px;
    top: -71px;
    position: absolute;
    filter: blur(250px);
  }
  &::after {
    content: ' ';
    border-radius: 261px;
    background: #9437ff;
    width: 261px;
    height: 261px;
    left: -131px;
    bottom: -51px;
    position: absolute;
    filter: blur(250px);
  }
  .container {
    padding: 100px 15px 219px;
    position: relative;
  }
  .talktous-text {
    position: relative;
    h1 {
      color: #4e4fdc;
      font-family: 'Epilogue', sans-serif !important;
      font-size: 36px;
      font-weight: 800;
      line-height: 40px;
      text-transform: capitalize;
      margin-bottom: 18px;
    }
    span {
      color: #44444d;
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
    }
    button {
      border-radius: 8px;
      background: #4e4fdc;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      outline: 0;
      border: 0;
      display: flex;
      padding: 24px 36px;
      align-items: flex-start;
      gap: 8px;
      color: #fff;
      text-align: center;
      font-family: 'Poppins', sans-serif !important;
      font-size: 18px;
      font-weight: 500;
      line-height: 18px;
      margin-top: 32px;
      cursor: pointer;
      &:hover {
        background: #4748c8;
        cursor: pointer;
      }
    }
  }
  .talktous-img {
    position: absolute;
    bottom: -3px;
    right: 0;
  }
}

@media (max-width: 991.5px) {
  .secondmain-section {
    .secondmain-section-data {
      h1 {
        font-size: 36px;
        line-height: 40px;
      }
    }
  }
  .talktous-section {
    .talktous-img {
      position: absolute;
      bottom: -53px;
      right: -91px;
      svg {
        width: 399px;
        height: 399px;
      }
    }
  }
}

@media (max-width: 768px) {
  .secondmain-section {
    // padding: 68px 0 100px;
    padding: 68px 0 122px;
    .secondmain-section-data {
      grid-template-columns: 1fr;
      label {
        font-size: 14px;
        line-height: 20px;
      }
      h1 {
        font-size: 36px;
        line-height: 40px;
        margin: 36px 0;
      }
      .animationimg {
        svg {
          width: 280px;
          height: auto;
        }
      }
    }
    .secondmain-accordian {
      margin-top: 120px;
    }
  }
  .talktous-section {
    &::before {
      right: -81px;
      top: -89px;
    }
    &::after {
      display: none;
    }
    .container {
      padding: 129px 15px 215px;
    }
    .talktous-text {
      h1 {
        font-size: 32px;
        margin-bottom: 28px;
      }
    }
  }
}
