/* top navbar */
.top-navbar {
  background-color: #fff;
  padding: 10px;
  text-align: center;
  h3 {
    color: #1e1f20;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 1.7px;
    margin: 0;
    font-family: 'Epilogue', sans-serif !important;
  }

  // New changes
  display: none;
}

/* main-navbar */
.main-navbar {
  background: #091a2a;
  padding: 35px 0;
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .mobile-icon {
      display: none;
    }
    .menu-list {
      ul {
        display: inline-flex;
        gap: 40px;
        text-align: right;
        li {
          list-style: none;
          //position: relative;
          a {
            color: #fff;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            text-decoration: none;
            cursor: pointer;
            &:hover {
              a {
                color: #4e4fdc;
              }
              svg path {
                stroke: #4e4fdc;
              }
            }
            svg {
              margin-left: 8px;
            }
          }
          .submenu {
            position: absolute;
            display: inline-flex;
            flex-direction: column;
            grid-gap: 12px;
            gap: 12px;
            width: 300px;
            padding: 23px 10px;
            text-align: left;
            //background: rgb(9 26 42 / 90%);
            z-index: 9;
            left: 0;
            border-radius: 8px;
            top: 30px;
            opacity: 0;
            visibility: hidden;
            transition: 0.5s all;
            li {
              a:hover {
                font-size: 15px;
              }
            }
          }
        }
        .solutions .submenu {
          width: 400px;
        }
        .about .submenu {
          width: 200px;
        }
        .services:hover .submenu {
          opacity: 1;
          visibility: visible;
          left: 32.5%;
        }
        .solutions:hover .submenu {
          opacity: 1;
          visibility: visible;
          left: 45%;
        }
        .resources:hover .submenu {
          opacity: 1;
          visibility: visible;
          left: 57.8%;
        }
        .about:hover .submenu {
          opacity: 1;
          visibility: visible;
          left: 82.5%;
        }
      }
      .mobile-info {
        display: none;
      }
    }
  }
}

// new css
.main-navbar .navbar .menu-list ul li:hover::after {
  content: ' ';
  width: 100%;
  left: 0;
  top: 30px;
  z-index: 3;
  position: absolute;
  background: rgb(9 26 42 / 90%);
  border-radius: 8px;
}
.services:hover::after {
  height: 214px;
}
.solutions:hover::after {
  height: 154px;
}
.resources:hover::after {
  height: 124px;
}
.about:hover::after {
  height: 94px;
}
.main-navbar .navbar .menu-list ul li .submenu li:hover::after {
  all: unset;
}

/* responsive */
@media (max-width: 1300px) and (min-width: 992px) {
  .main-navbar .navbar .menu-list ul .services:hover .submenu {
    left: 24.3%;
  }
  .main-navbar .navbar .menu-list ul .solutions:hover .submenu {
    left: 38%;
  }
  .main-navbar .navbar .menu-list ul .resources:hover .submenu {
    left: 52.6%;
  }
  .main-navbar .navbar .menu-list ul .about:hover .submenu {
    left: 80.4%;
  }
}
@media (max-width: 991.5px) {
  .main-navbar {
    padding: 15px 0;
    .navbar {
      position: unset;
      .logo img {
        filter: brightness(0) invert(1);
      }
      .menu-list {
        position: absolute;
        background: #fff;
        width: 100%;
        left: 0;
        height: fit-content;
        // top: -100%;
        transform: translateY(-100%);
        z-index: 99;
        border-top: 0.5px solid #44444d;
        transition: 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28);
        ul {
          display: flex;
          gap: 13px;
          text-align: left;
          flex-direction: column;
          align-items: flex-start;
          padding: 20px 15px;
          li {
            width: 100%;
            a {
              color: #091a2a;
              font-size: 12px;
              font-weight: 500;
              line-height: 18px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              svg path {
                stroke: #091a2a;
              }
            }
            .submenu {
              position: unset;
              opacity: 1;
              visibility: visible;
              background-color: transparent;
              width: 100%;
              height: 0;
              overflow: hidden;
              padding: 0 !important;
              display: block;
            }
            .showsubmenu {
              padding: 12px 12px 12px 32px !important;
              height: fit-content;
              display: inline-flex;
            }
          }
        }
        .mobile-info {
          display: flex;
          flex-direction: column;
          padding: 24px 15px 40px 15px;
          border-top: 0.5px solid #44444d;
          h2 {
            color: #000;
            font-size: 14px;
            font-weight: 700;
            line-height: 22px;
            margin-bottom: 15px;
          }
          span {
            color: #000;
            font-size: 12px;
            font-weight: 500;
            line-height: 26px;
          }
        }
      }
      .showmenu {
        transform: translateY(0%);
        //top: 94px;

        top: 59px;
      }
      .mobile-icon {
        display: block;
        cursor: pointer;
      }
    }
  }
  .main-navbar .navbar .menu-list ul li:hover::after {
    all: unset;
  }
}
@media (max-width: 768px) {
  .top-navbar h3 {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1.2px;
  }
  .navbar .logo img {
    width: 170px;
  }

  .main-navbar {
    .navbar {
      .menu-list {
        ul {
          li {
            .submenu {
              li {
                a:hover {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 350px) {
  .main-navbar {
    zoom: 0.9;
  }
}
